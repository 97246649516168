import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { Button, ButtonGroup, Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Row } from "reactstrap";
import toastr from "toastr";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";
import { ACCOUNTS_CLIENT, COUNTRIES_CLIENT, DOCUMENTS_CLIENT } from "../../../api/api_helper";
import { DocumentSpec, DocumentType, ProfileType, TaxUnit } from "../../../api/hotel-api.service";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Checkbox from "../../../components/Inputs/Checkbox";
import FileInput from "../../../components/Inputs/FileInput";
import FloatingSelectInput from "../../../components/Inputs/FloatingSelectInput";
import TextInput from "../../../components/Inputs/TextInput";
import SuccessModal from "../../../components/Modals/SuccessModal";
import LoadingSpinner from "../../../components/UI/LoadingSpinner";
import { regionNames } from "../../../helpers/intl";
import useModal from "../../../hooks/useModal";

const defaultSupportedLanguages = ["fr", "en", "nl"];

const ClientForm = ({ editMode }) => {
	const { t } = useTranslation();
	const { id } = useParams();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [countries, setCountries] = useState([]);
	const [taxBrackets, setTaxBrackets] = useState([]);
	const [discounts, setDiscounts] = useState([]);
	const [clientData, setClientData] = useState();
	const [isPmsConnectionTesting, setIsPmsConnectionTesting] = useState(false);
	const [invoiceRecipientsEmails, setInvoiceRecipientsEmails] = useState([]);
	const [invoiceRecipientsEmailOptions, setInvoiceRecipientsEmailOptions] = useState(null);
	const { open: openSuccessModal, toggle: toggleSuccessModal } = useModal();
	const languages = [
		{ code: "fr", name: "French" },
		{ code: "en", name: "English" },
		{ code: "nl", name: "Dutch" },
	];

	const validationRegex = {
		name: {
			// regex: /^[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9]$)?/,
			regex: /^[a-z0-9]+(\-[a-z0-9]+)*$/,
			message: t("UrlFormatInvalid"),
		},
		siretNumber: {
			regex: /^(\s?\d){14}$/,
			message: t("SiretNumberFormatError"),
		},
	};

	const formik = useFormik({
		enableReinitialize: true,
		validateOnBlur: true,
		initialValues: {
			/* GENERAL INFO */
			title: clientData?.title || "",
			name: clientData?.name || "", //url subdomain
			address: clientData?.address || "",
			city: clientData?.city || "",
			country: clientData?.country || "",
			taxBracketId: clientData?.taxBracketId || null,
			zipCode: clientData?.zipCode || "",
			receptionPhoneNumber: clientData?.receptionPhoneNumber || "",
			receptionEmail: clientData?.receptionEmail || "",
			salesDepartmentPhoneNumber: clientData?.salesDepartmentPhoneNumber || "",
			salesDepartmentEmail: clientData?.salesDepartmentEmail || "",
			managerFirstName: clientData?.contacts.find((e) => e.profile === ProfileType.Manager)?.firstName || "",
			managerLastName: clientData?.contacts.find((e) => e.profile === ProfileType.Manager)?.lastName || "",
			managerPhoneNumber: clientData?.contacts.find((e) => e.profile === ProfileType.Manager)?.phoneNumber || "",
			managerEmail: clientData?.contacts.find((e) => e.profile === ProfileType.Manager)?.email || "",
			salesAgentFirstName: clientData?.contacts.find((e) => e.profile === ProfileType.SalesAgent)?.firstName || "",
			salesAgentLastName: clientData?.contacts.find((e) => e.profile === ProfileType.SalesAgent)?.lastName || "",
			salesAgentPhoneNumber: clientData?.contacts.find((e) => e.profile === ProfileType.SalesAgent)?.phoneNumber || "",
			salesAgentEmail: clientData?.contacts.find((e) => e.profile === ProfileType.SalesAgent)?.email || "",
			csmFirstName: clientData?.contacts.find((e) => e.profile === ProfileType.CSM)?.firstName || "",
			csmLastName: clientData?.contacts.find((e) => e.profile === ProfileType.CSM)?.lastName || "",
			csmPhoneNumber: "",
			csmEmail: clientData?.contacts.find((e) => e.profile === ProfileType.CSM)?.email || "",
			/* THIRD-PARTY SERVICES INTEGRATION */
			storeId: clientData?.storeId || "",
			storeKey: clientData?.storeKey || "",
			pmsConnectionString: clientData?.pmsConnectionString || "",
			pmsType: clientData?.pmsType,
			externalId: clientData?.externalId || "",
			/* BILLING */
			hasInactiveInvoices: clientData?.hasInactiveInvoices || false,
			companyName: clientData?.billingSetting?.companyName || "",
			siretNumber: clientData?.billingSetting?.siretNumber || "",
			rcsNumber: clientData?.billingSetting?.rcsNumber || "",
			tvaNumber: clientData?.billingSetting?.tvaNumber || "",
			bankName: clientData?.billingSetting?.bankName || "",
			ibanNumber: clientData?.billingSetting?.ibanNumber || "",
			bicNumber: clientData?.billingSetting?.bicNumber || "",
			sddurm: clientData?.sddurm || "",
			sddExpiryDate: clientData?.sddExpiryDate ? moment(new Date(clientData?.sddExpiryDate)).format("YYYY-MM-DD") : "",
			billingSettingFirstName: clientData?.billingSetting?.firstName || "",
			billingSettingLastName: clientData?.billingSetting?.lastName || "",
			/* FIRST INVOICE */
			firstInvoiceDate: clientData?.billingSetting?.firstInvoiceDate ? moment(new Date(clientData?.billingSetting?.firstInvoiceDate)).format("YYYY-MM-DD") : "",
			// invoiceRecipientsEmail: clientData?.billingSetting?.invoiceRecipientsEmails?.find(Boolean) || "",
			minAmount: clientData?.billingSetting?.minAmount || "",
			minAmountTax: clientData?.billingSetting?.minAmountTax || "",
			maxAmount: clientData?.billingSetting?.maxAmount || "",
			maxAmountTax: clientData?.billingSetting?.maxAmountTax || "",
			technicalCost: clientData?.billingSetting?.technicalCost || "",
			technicalCostTax: clientData?.billingSetting?.technicalCostTax || "",
			directSaleCommission: clientData?.billingSetting?.directSaleCommission || "",
			directSaleCommissionTax: clientData?.billingSetting?.directSaleCommissionTax || "",
			onlineSaleCommission: clientData?.billingSetting?.onlineSaleCommission || "",
			onlineSaleCommissionTax: clientData?.billingSetting?.onlineSaleCommissionTax || "",
			monthlySubscription: clientData?.billingSetting?.monthlySubscription || "",
			monthlySubscriptionTax: clientData?.billingSetting?.monthlySubscriptionTax || "",
			marketplaceSaleCommission: clientData?.billingSetting?.marketplaceSaleCommission || "",
			marketplaceSaleCommissionTax: clientData?.billingSetting?.marketplaceSaleCommissionTax || "",
			notificationEmails: clientData?.notificationEmails || [],
			supportedLanguages: clientData?.supportedLanguages || defaultSupportedLanguages,
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required(t("RequiredFieldInputError")),
			name: Yup.string().required(t("RequiredFieldInputError")),
			// .matches(validationRegex.name.regex, validationRegex.name.message)
			// .max(64, t("UrlFormatInvalid")),
			managerFirstName: Yup.string(), //.required(t("RequiredFieldInputError")),
			managerLastName: Yup.string(), //.required(t("RequiredFieldInputError")),
			managerPhoneNumber: Yup.string(), //.required(t("RequiredFieldInputError")),
			managerEmail: Yup.string().email(t("InvalidEmailFormat")), //.required(t("RequiredFieldInputError")),
			address: Yup.string().required(t("RequiredFieldInputError")),
			zipCode: Yup.string().required(t("RequiredFieldInputError")),
			city: Yup.string().required(t("RequiredFieldInputError")),
			country: Yup.string().required(t("RequiredFieldInputError")),
			taxBracketId: Yup.string().required(t("RequiredFieldInputError")),
			hasInactiveInvoices: Yup.boolean(),
			companyName: Yup.string().when("hasInactiveInvoices", { is: false, then: Yup.string().required(t("RequiredFieldInputError")) }),
			siretNumber: Yup.string()
				.matches(validationRegex.siretNumber.regex, validationRegex.siretNumber.message)
				.when("hasInactiveInvoices", { is: false, then: Yup.string().required(t("RequiredFieldInputError")) }),
			tvaNumber: Yup.string().when("hasInactiveInvoices", { is: false, then: Yup.string().required(t("RequiredFieldInputError")) }),
			ibanNumber: Yup.string()
				.min(5, t("IbanNumberFormatError"))
				.when("hasInactiveInvoices", { is: false, then: Yup.string().required(t("RequiredFieldInputError")) }),
			rcsNumber: Yup.string().when("hasInactiveInvoices", { is: false, then: Yup.string().required(t("RequiredFieldInputError")) }),
			bicNumber: Yup.string().when("hasInactiveInvoices", { is: false, then: Yup.string().required(t("RequiredFieldInputError")) }),
			bankName: Yup.string().when("hasInactiveInvoices", { is: false, then: Yup.string().required(t("RequiredFieldInputError")) }),
			csmEmail: Yup.string()
				.email(t("InvalidEmailFormat"))
				.notOneOf([Yup.ref("managerEmail")], t("Cs  mAndManagerSimilarEmailError")),
			// billingSettingFirstName: Yup.string().when("hasInactiveInvoices", { is: false, then: Yup.string().required(t("RequiredFieldInputError")) }),
			// billingSettingLastName: Yup.string().when("hasInactiveInvoices", { is: false, then: Yup.string().required(t("RequiredFieldInputError")) }),
			supportedLanguages: Yup.array(Yup.string()).min(1, t("RequiredFieldInputError")),
			notificationEmails: Yup.array(Yup.string().email(t("InvalidEmailFormat"))),
		}),
		onSubmit: async (values) => {
			const managerId = clientData?.contacts.find((e) => e.profile === ProfileType.Manager)?.id;
			const salesAgentId = clientData?.contacts.find((e) => e.profile === ProfileType.SalesAgent)?.id;
			const csmId = clientData?.contacts.find((e) => e.profile === ProfileType.CSM)?.id;
			const payload = {
				title: values.title,
				name: values.name,
				address: values.address,
				city: values.city,
				country: values.country,
				taxBracketId: values.taxBracketId,
				// countryTaxId,
				zipCode: values.zipCode,
				receptionPhoneNumber: values.receptionPhoneNumber,
				receptionEmail: values.receptionEmail,
				salesDepartmentPhoneNumber: values.salesDepartmentPhoneNumber,
				salesDepartmentEmail: values.salesDepartmentEmail,
				hasInactiveInvoices: values.hasInactiveInvoices,
				notificationEmails: values.notificationEmails,
				supportedLanguages: values.supportedLanguages ? values.supportedLanguages.filter((e) => e) : defaultSupportedLanguages,
				billingSettingId: clientData?.billingSettingId || undefined,
				billingSetting: {
					id: clientData?.billingSettingId || undefined,
					companyName: values.companyName,
					siretNumber: values.siretNumber,
					rcsNumber: values.rcsNumber,
					tvaNumber: values.tvaNumber,
					firstName: values.billingSettingFirstName,
					lastName: values.billingSettingLastName,
					bankName: values.bankName,
					ibanNumber: values.ibanNumber,
					bicNumber: values.bicNumber,
					firstInvoiceDate: new Date(values.firstInvoiceDate),
					minAmount: values.minAmount,
					minAmountTax: values.minAmountTax,
					maxAmount: values.maxAmount,
					maxAmountTax: values.maxAmountTax,
					technicalCost: values.technicalCost,
					technicalCostTax: values.technicalCostTax,
					monthlySubscription: values.monthlySubscription,
					monthlySubscriptionTax: values.monthlySubscriptionTax,
					directSaleCommission: values.directSaleCommission,
					directSaleCommissionTax: values.directSaleCommissionTax,
					onlineSaleCommission: values.onlineSaleCommission,
					onlineSaleCommissionTax: values.onlineSaleCommissionTax,
					marketplaceSaleCommission: values.marketplaceSaleCommission,
					marketplaceSaleCommissionTax: values.marketplaceSaleCommissionTax,
					invoiceRecipientsEmails: invoiceRecipientsEmails.map((e) => e.label),
					discounts,
				},
				sddurm: values.sddurm,
				sddExpiryDate: new Date(values.sddExpiryDate),
				contacts: [
					{
						...(!!id && !!managerId && { id: managerId }),
						// countryTaxId,
						profile: ProfileType.Manager,
						firstName: values.managerFirstName,
						lastName: values.managerLastName,
						phoneNumber: values.managerPhoneNumber,
						email: values.managerEmail,
					},
					{
						...(!!id && !!salesAgentId && { id: salesAgentId }),
						// countryTaxId,
						profile: ProfileType.SalesAgent,
						firstName: values.salesAgentFirstName,
						lastName: values.salesAgentLastName,
						phoneNumber: values.salesAgentPhoneNumber,
						email: values.salesAgentEmail,
					},
					{
						...(!!id && !!csmId && { id: csmId }),
						// countryTaxId,
						profile: ProfileType.CSM,
						firstName: values.csmFirstName,
						lastName: values.csmLastName,
						phoneNumber: values.csmPhoneNumber,
						email: values.csmEmail,
					},
				],
				pmsType: values.pmsType,
				pmsConnectionString: values.pmsConnectionString,
				externalId: values.externalId,
			};

			setLoading(true);
			try {
				if (id) {
					await ACCOUNTS_CLIENT.update(id, { ...payload, id });
				} else {
					await ACCOUNTS_CLIENT.create(payload);
				}
				toggleSuccessModal();
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		},
	});

	// TODO: Refactor
	useEffect(() => {
		setLoading(true);
		if (id) {
			const promises = [ACCOUNTS_CLIENT.getById(id), COUNTRIES_CLIENT.getAll(false)];
			Promise.all(promises)
				.then((responses) => {
					const [clientResponse, countriesResponse] = responses;
					setCountries(countriesResponse);
					setTaxBrackets(countriesResponse.find((e) => e.name === clientResponse.country)?.taxBrackets ?? []);
					setClientData(clientResponse);
					setInvoiceRecipientsEmails(clientResponse.billingSetting?.invoiceRecipientsEmails?.map((e, idx) => ({ label: e, value: idx })) || []);
					setDiscounts(clientResponse.billingSetting?.discounts || []);
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			COUNTRIES_CLIENT.getAll(false)
				.then((response) => {
					setCountries(response);
				})
				.catch((error) => console.log(error))
				.finally(() => setLoading(false));
		}
	}, []);

	useEffect(() => {
		const { receptionEmail, salesDepartmentEmail, managerEmail, salesAgentEmail } = formik.values;
		setInvoiceRecipientsEmailOptions(_.uniq([receptionEmail, salesDepartmentEmail, managerEmail, salesAgentEmail]).map((e, idx) => ({ label: e, value: idx })));
	}, [formik.values]);

	const handleCountryChange = (country) => {
		formik.setFieldValue("country", country.name);
		setTaxBrackets(country.taxBrackets);
	};

	const handlePmsTypeChange = (e) => {
		formik.handleChange(e);
	};

	const handleAddDiscount = () => {
		const newDiscount = {
			value: 0,
			unit: TaxUnit.Currency,
			date: new Date(),
			uuid: uuid(),
		};
		setDiscounts((prev) => [...prev, newDiscount]);
	};

	const handleChangeDiscount = (id, field, value) => {
		setDiscounts((prev) => prev.map((e) => (id === e.id || id === e.uuid ? { ...e, [field]: value } : e)));
	};

	const handleRecipientsEmails = (opt) => {
		setInvoiceRecipientsEmails(opt);
	};
	const handleSupportedLanguageChange = (languageCode) => {
		const updatedLanguages = formik.values.supportedLanguages.includes(languageCode)
			? formik.values.supportedLanguages.filter((code) => code !== languageCode)
			: [...formik.values.supportedLanguages, languageCode];

		formik.setFieldValue("supportedLanguages", updatedLanguages);
	};

	const handleAddNotificationEmail = () => {
		formik.setFieldValue("notificationEmails", [...formik.values.notificationEmails, ""]);
	};

	const handleNotificationEmailChange = (emailIdx, value) => {
		formik.setFieldValue(
			"notificationEmails",
			formik.values.notificationEmails.map((e, idx) => (idx === emailIdx ? value : e))
		);
	};

	const handleNotificationEmailDelete = (emailIdx) => {
		formik.setFieldValue(
			"notificationEmails",
			formik.values.notificationEmails.filter((e, idx) => idx !== emailIdx)
		);
	};

	const handleSubmitForm = (e) => {
		e.preventDefault();
		const errorFieldId = Object.keys(formik.errors).find(Boolean);
		if (errorFieldId) {
			const el = document.getElementById(errorFieldId);
			el?.scrollIntoView();
			toastr.error(t("ErrorInForm"));
		}
		formik.handleSubmit();
	};

	const uploadFile = async (file, type) => {
		let fileToUpload = {
			data: file[0],
			fileName: file[0].name,
		};
		try {
			const response = await DOCUMENTS_CLIENT.upload(clientData.id, type, DocumentSpec.None, fileToUpload);
			setClientData((prev) => ({ ...prev, documents: [...prev.documents, response] }));
		} catch (error) {
			toastr.error(t("FileUploadError"));
		}
	};

	const deleteFile = async (fileId, type) => {
		try {
			await DOCUMENTS_CLIENT.delete(type, fileId);
			setClientData((prev) => ({ ...prev, documents: prev.documents.filter((e) => e.id !== fileId) }));
		} catch (error) {
			toastr.error(t("FileUploadError"));
		}
	};

	const updateFile = async (file, fileId, type) => {
		await deleteFile(fileId);
		await uploadFile(file, type);
	};

	const handleTestPMSConnection = async () => {
		setIsPmsConnectionTesting(true);
		try {
			const { externalId, pmsType, pmsConnectionString } = formik.values;
			await ACCOUNTS_CLIENT.testPMSConnection(externalId, pmsType, pmsConnectionString);
			toastr.success(t("PMSConnectionSuccessful"));
		} catch (error) {
			toastr.error(error);
		} finally {
			setIsPmsConnectionTesting(false);
		}
	};

	return (
		<div className="page-content">
			<MetaTags>
				<title>{editMode ? t("EditClient") : t("AddClient")} | Quotelo</title>
			</MetaTags>

			<Breadcrumbs title={t("Clients")} breadcrumbItem={editMode ? t("EditClient") : t("AddClient")} link="/clients" />

			<Container fluid>
				<Form className="needs-validation" onSubmit={handleSubmitForm}>
					{loading ? (
						<Card>
							<CardBody>
								<LoadingSpinner />
							</CardBody>
						</Card>
					) : (
						<>
							{/* GENERAL INFO */}
							<Card>
								<CardBody>
									<CardTitle>{t("GeneralInformation")}</CardTitle>
									<Row>
										{/* LEFT COLUMN */}
										<Col md={6}>
											<Row>
												<Label className="fw-bold">{t("Hotel")}</Label>
												<Col md={6}>
													<TextInput
														htmlId="title"
														name="title"
														label={t("HotelTitle")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.title}
														validation={formik}
														invalid={formik.touched.title && formik.errors.title}
														isRequired
													/>
												</Col>
												<Col md={6}>
													<TextInput
														htmlId="name"
														name="name"
														label={t("URL")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.name}
														validation={formik}
														invalid={formik.touched.name && formik.errors.name}
														isRequired
													/>
												</Col>

												<Col md={6}>
													<TextInput
														htmlId="address"
														name="address"
														label={t("Address")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.address || ""}
														validation={formik}
														invalid={formik.touched.address && formik.errors.address}
														isRequired
													/>
												</Col>
												<Col md={6}>
													<TextInput
														htmlId="zipCode"
														name="zipCode"
														label={t("ZipCode")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.zipCode || ""}
														validation={formik}
														invalid={formik.touched.zipCode && formik.errors.zipCode}
														isRequired
													/>
												</Col>
												<Col md={6}>
													<FloatingSelectInput
														label={t("Country")}
														options={countries}
														getOptionLabel={(option) => regionNames.of(option.code)}
														getOptionValue={(option) => option.name}
														value={countries.find((c) => c.name === formik.values.country)}
														onChange={(newCountry) => handleCountryChange(newCountry)}
														name={"country"}
														validation={formik}
														placeholder={t("SelectCountry")}
													/>
												</Col>
												<Col md={6}>
													<TextInput
														htmlId="city"
														name="city"
														label={t("City")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.city || ""}
														validation={formik}
														invalid={formik.touched.city && formik.errors.city}
														isRequired
													/>
												</Col>
												<Col md={6}>
													<FloatingSelectInput
														label={t("TaxBracketName")}
														options={taxBrackets}
														getOptionLabel={(option) => option.taxDenomination}
														getOptionValue={(option) => option.id}
														value={taxBrackets?.find((e) => e.id === formik.values.taxBracketId)}
														onChange={(taxBracket) => formik.setFieldValue("taxBracketId", taxBracket.id)}
														name={"taxBracketId"}
														validation={formik}
														placeholder={t("SelectTaxBracket")}
													/>
												</Col>
												<Col md={6}></Col>
												<Col md={6}>
													<TextInput
														htmlId="receptionPhoneNumber"
														name="receptionPhoneNumber"
														label={t("ReceptionPhoneNumber")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.receptionPhoneNumber || ""}
														validation={formik}
														invalid={formik.touched.receptionPhoneNumber && formik.errors.receptionPhoneNumber}
													/>
												</Col>
												<Col md={6}>
													<TextInput
														htmlId="receptionEmail"
														name="receptionEmail"
														label={t("ReceptionEmail")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.receptionEmail || ""}
														validation={formik}
														invalid={formik.touched.receptionEmail && formik.errors.receptionEmail}
													/>
												</Col>
												<Col md={6}>
													<TextInput
														htmlId="salesDepartmentPhoneNumber"
														name="salesDepartmentPhoneNumber"
														label={t("SalesDepartmentPhoneNumber")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.salesDepartmentPhoneNumber || ""}
														validation={formik}
														invalid={formik.touched.salesDepartmentPhoneNumber && formik.errors.salesDepartmentPhoneNumber}
													/>
												</Col>
												<Col md={6}>
													<TextInput
														htmlId="salesDepartmentEmail"
														name="salesDepartmentEmail"
														label={t("SalesDepartmentEmail")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.salesDepartmentEmail || ""}
														validation={formik}
														invalid={formik.touched.salesDepartmentEmail && formik.errors.salesDepartmentEmail}
													/>
												</Col>
												<Col>
													<Label className="fw-bold">{t("SupportedLanguages")}</Label>

													<Col md={6}>
														{languages.map((language, idx) => (
															<div className="d-flex align-items-center mb-2" key={idx}>
																<Checkbox
																	name="supportedLanguages"
																	checked={formik.values.supportedLanguages.includes(language.code)}
																	onChange={() => handleSupportedLanguageChange(language.code)}
																	label={language.name}
																	htmlId={language.name}
																/>
															</div>
														))}
													</Col>
												</Col>
											</Row>
										</Col>
										{/* RIGHT COLUMN */}
										<Col md={6}>
											<Row>
												<Label className="fw-bold">{t("Manager")}</Label>
												<Col md={6}>
													<TextInput
														htmlId="managerFirstName"
														name="managerFirstName"
														label={t("FirstName")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.managerFirstName}
														validation={formik}
														invalid={formik.touched.managerFirstName && formik.errors.managerFirstName}
														isRequired
													/>
												</Col>
												<Col md={6}>
													<TextInput
														htmlId="managerLastName"
														name="managerLastName"
														label={t("LastName")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.managerLastName}
														validation={formik}
														invalid={formik.touched.managerLastName && formik.errors.managerLastName}
														isRequired
													/>
												</Col>
												<Col md={6}>
													<TextInput
														htmlId="managerPhoneNumber"
														name="managerPhoneNumber"
														label={t("PhoneNumber")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.managerPhoneNumber || ""}
														validation={formik}
														invalid={formik.touched.managerPhoneNumber && formik.errors.managerPhoneNumber}
														isRequired
													/>
												</Col>
												<Col md={6}>
													<TextInput
														htmlId="managerEmail"
														name="managerEmail"
														label={t("Email")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.managerEmail || ""}
														validation={formik}
														invalid={formik.touched.managerEmail && formik.errors.managerEmail}
														isRequired
													/>
												</Col>
												<Label className="fw-bold">{t("SalesAgent")}</Label>
												<Col md={6}>
													<TextInput
														htmlId="salesAgentFirstName"
														name="salesAgentFirstName"
														label={t("FirstName")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.salesAgentFirstName || ""}
														validation={formik}
														invalid={formik.touched.salesAgentFirstName && formik.errors.salesAgentFirstName}
													/>
												</Col>
												<Col md={6}>
													<TextInput
														htmlId="salesAgentLastName"
														name="salesAgentLastName"
														label={t("LastName")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.salesAgentLastName || ""}
														validation={formik}
														invalid={formik.touched.salesAgentLastName && formik.errors.salesAgentLastName}
													/>
												</Col>
												<Col md={6}>
													<TextInput
														htmlId="salesAgentPhoneNumber"
														name="salesAgentPhoneNumber"
														label={t("PhoneNumber")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.salesAgentPhoneNumber || ""}
														validation={formik}
														invalid={formik.touched.salesAgentPhoneNumber && formik.errors.salesAgentPhoneNumber}
													/>
												</Col>
												<Col md={6}>
													<TextInput
														htmlId="salesAgentEmail"
														name="salesAgentEmail"
														label={t("Email")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.salesAgentEmail || ""}
														validation={formik}
														invalid={formik.touched.salesAgentEmail && formik.errors.salesAgentEmail}
													/>
												</Col>
												<Label className="fw-bold">{t("CSM")}</Label>
												<Col md={6}>
													<TextInput
														htmlId="csmFirstName"
														name="csmFirstName"
														label={t("FirstName")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.csmFirstName || ""}
														validation={formik}
														invalid={formik.touched.csmFirstName && formik.errors.csmFirstName}
													/>
												</Col>
												<Col md={6}>
													<TextInput
														htmlId="csmLastName"
														name="csmLastName"
														label={t("LastName")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.csmLastName || ""}
														validation={formik}
														invalid={formik.touched.csmLastName && formik.errors.csmLastName}
													/>
												</Col>

												<Col md={6}>
													<TextInput
														htmlId="csmEmail"
														name="csmEmail"
														label={t("Email")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.csmEmail || ""}
														validation={formik}
														invalid={formik.touched.csmEmail && formik.errors.csmEmail}
													/>
												</Col>
												<Label className="fw-bold">{t("Notifications")}</Label>
												<Col md={6}>
													<Button color="link" onClick={handleAddNotificationEmail} className="p-0 mb-3">
														<i className="bx bx-plus"></i>
														{t("Add")}
													</Button>
													{formik?.values?.notificationEmails &&
														formik.values.notificationEmails.map((email, idx) => (
															<>
																<div className="d-flex align-items-center mb-2" key={idx}>
																	<Input
																		name="notificationEmails"
																		value={email}
																		onChange={(e) => handleNotificationEmailChange(idx, e.target.value)}
																		placeholder={t("example@mail.com")}
																	/>
																	<button
																		style={{ background: "none", border: "none" }}
																		className="text-danger"
																		onClick={() => handleNotificationEmailDelete(idx)}
																	>
																		<i className="bx bx-trash ms-2"></i>
																	</button>
																</div>
																<div className="text-danger mb-1">{formik.errors?.notificationEmails?.[idx] || ""}</div>
															</>
														))}
												</Col>
											</Row>
										</Col>
									</Row>
								</CardBody>
							</Card>

							{/* PAYMENT AND PMS INTEGRATION */}
							<Card>
								<CardBody>
									<CardTitle>{t("BillingAndPMSIntegration")}</CardTitle>
									<Row>
										{/* LEFT COLUMN */}
										<Col md={12}>
											<Row>
												<Label className="fw-bold">{t("Payment")}</Label>
												<Col md={3}>
													<TextInput
														htmlId="storeId"
														name="storeId"
														label={t("StoreId")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.storeId}
														validation={formik}
														invalid={formik.touched.storeId && formik.errors.storeId}
													/>
												</Col>
												<Col md={3}>
													<TextInput
														htmlId="storeKey"
														name="storeKey"
														label={t("StoreKey")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.storeKey}
														validation={formik}
														invalid={formik.touched.storeKey && formik.errors.storeKey}
													/>
												</Col>
											</Row>
										</Col>
										{/* RIGHT COLUMN */}
										<Col md={12}>
											<Row>
												<Label className="fw-bold">{t("PMS")}</Label>
												<Col md={3}>
													<TextInput
														htmlId="pmsConnectionString"
														name="pmsConnectionString"
														label={t("PmsConnectionString")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.pmsConnectionString}
														validation={formik}
														invalid={formik.touched.pmsConnectionString && formik.errors.pmsConnectionString}
													/>
												</Col>
												<Col md={3}>
													<TextInput
														htmlId="externalId"
														name="externalId"
														label={t("ExternalId")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.externalId}
														validation={formik}
														invalid={formik.touched.externalId && formik.errors.externalId}
														disabled
														readOnly
													/>
												</Col>
												<Col md={2}>
													<Button color="info" loading={isPmsConnectionTesting} disabled={isPmsConnectionTesting} onClick={handleTestPMSConnection}>
														{isPmsConnectionTesting ? <LoadingSpinner color="white" /> : t("TestPMSConnection")}
													</Button>
												</Col>
											</Row>
										</Col>
									</Row>
								</CardBody>
							</Card>

							{clientData?.documents ? (
								<Card>
									<CardBody>
										<CardTitle>{t("Documents")}</CardTitle>
										<Row>
											<Col>
												<FileInput
													label={t("HotelKBIS")}
													file={clientData.documents.find((e) => e.type === DocumentType.HotelKBIS)}
													documentType={DocumentType.HotelKBIS}
													docType={"*"}
													uploadFiles={(file) => uploadFile(file, DocumentType.HotelKBIS)}
													deleteFiles={(fileId) => deleteFile(fileId, DocumentType.HotelKBIS)}
													updateFiles={(file, fileId) => updateFile(file, fileId, DocumentType.HotelKBIS)}
												/>
											</Col>
											<Col>
												<FileInput
													label={t("HotelIBAN")}
													file={clientData.documents.find((e) => e.type === DocumentType.HotelIBAN)}
													documentType={DocumentType.HotelIBAN}
													docType={"*"}
													uploadFiles={(file) => uploadFile(file, DocumentType.HotelIBAN)}
													deleteFiles={(fileId) => deleteFile(fileId, DocumentType.HotelIBAN)}
													updateFiles={(file, fileId) => updateFile(file, fileId, DocumentType.HotelIBAN)}
												/>
											</Col>
											<Col>
												<FileInput
													label={t("HotelIDCARD")}
													file={clientData.documents.find((e) => e.type === DocumentType.HotelIDCARD)}
													documentType={DocumentType.HotelIDCARD}
													docType={"*"}
													uploadFiles={(file) => uploadFile(file, DocumentType.HotelIDCARD)}
													deleteFiles={(fileId) => deleteFile(fileId, DocumentType.HotelIDCARD)}
													updateFiles={(file, fileId) => updateFile(file, fileId, DocumentType.HotelIDCARD)}
												/>
											</Col>
										</Row>
									</CardBody>
								</Card>
							) : null}

							{/* INVOICES */}
							<Card>
								<CardBody>
									<CardTitle>{t("Billing")}</CardTitle>
									<Row>
										<Col xs={12}>
											<div className="form-check mb-3">
												<label className="form-check-label">
													<input
														type="checkbox"
														name="hasInactiveInvoices"
														className="form-check-input"
														defaultChecked={formik.values.hasInactiveInvoices}
														onChange={formik.handleChange}
													/>
													{t("DisableInvoices")}
												</label>
											</div>
										</Col>
										{/* LEFT COLUMN - BILLING INFO */}
										<Col md={6}>
											<Row>
												<Label className="fw-bold">{t("GeneralInformation")}</Label>
												<Col md={6}>
													<TextInput
														htmlId="companyName"
														name="companyName"
														label={t("CompanyName2")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.companyName}
														validation={formik}
														invalid={formik.touched.companyName && formik.errors.companyName}
														isRequired={!formik.values.hasInactiveInvoices ? true : undefined}
													/>
												</Col>
												<Col md={6}>
													<TextInput
														htmlId="siretNumber"
														name="siretNumber"
														label={t("SiretNumber")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.siretNumber}
														validation={formik}
														invalid={formik.touched.siretNumber && formik.errors.siretNumber}
														isRequired={!formik.values.hasInactiveInvoices ? true : undefined}
													/>
												</Col>
												<Col md={6}>
													<TextInput
														htmlId="rcsNumber"
														name="rcsNumber"
														label={t("RcsNumber")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.rcsNumber || ""}
														validation={formik}
														invalid={formik.touched.rcsNumber && formik.errors.rcsNumber}
														isRequired={!formik.values.hasInactiveInvoices ? true : undefined}
													/>
												</Col>
												<Col md={6}>
													<TextInput
														htmlId="tvaNumber"
														name="tvaNumber"
														label={t("TvaNumber")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.tvaNumber || ""}
														validation={formik}
														invalid={formik.touched.tvaNumber && formik.errors.tvaNumber}
														isRequired={!formik.values.hasInactiveInvoices ? true : undefined}
													/>
												</Col>
												<Col md={6}>
													<TextInput
														htmlId="bankName"
														name="bankName"
														label={t("BankName")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.bankName || ""}
														validation={formik}
														invalid={formik.touched.bankName && formik.errors.bankName}
														isRequired={!formik.values.hasInactiveInvoices ? true : undefined}
													/>
												</Col>
												<Col md={6}>
													<TextInput
														htmlId="ibanNumber"
														name="ibanNumber"
														label={t("IbanNumber")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.ibanNumber || ""}
														validation={formik}
														invalid={formik.touched.ibanNumber && formik.errors.ibanNumber}
														isRequired={!formik.values.hasInactiveInvoices ? true : undefined}
													/>
												</Col>
												<Col md={6}>
													<TextInput
														htmlId="bicNumber"
														name="bicNumber"
														label={t("BicNumber")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.bicNumber || ""}
														validation={formik}
														invalid={formik.touched.bicNumber && formik.errors.bicNumber}
														isRequired={!formik.values.hasInactiveInvoices ? true : undefined}
													/>
												</Col>
												<Col md={6}>
													<TextInput
														htmlId="sddurm"
														name="sddurm"
														label={t("SDDURM")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.sddurm || ""}
														validation={formik}
														invalid={formik.touched.sddurm && formik.errors.sddurm}
													/>
												</Col>
												<Col md={6}>
													<TextInput
														type="date"
														htmlId="sddExpiryDate"
														name="sddExpiryDate"
														label={t("SDDExpiryDate")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.sddExpiryDate /*  || moment().format("YYYY-MM-DD") */}
														validation={formik}
														invalid={formik.touched.sddExpiryDate && formik.errors.sddExpiryDate}
													/>
												</Col>
												{/* FACTURE */}
											</Row>
										</Col>
										{/* RIGHT COLUMN - FACTURE AU NOM DE */}
										<Col md={6}>
											<Row>
												<Label className="fw-bold">{t("InvoiceRecipient")}</Label>
												<Col md={6}>
													<TextInput
														htmlId="billingSettingFirstName"
														name="billingSettingFirstName"
														label={t("FirstName")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.billingSettingFirstName}
														validation={formik}
														invalid={formik.touched.billingSettingFirstName && formik.errors.billingSettingFirstName}
														isRequired
													/>
												</Col>
												<Col md={6}>
													<TextInput
														htmlId="billingSettingLastName"
														name="billingSettingLastName"
														label={t("LastName")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.billingSettingLastName}
														validation={formik}
														invalid={formik.touched.billingSettingLastName && formik.errors.billingSettingLastName}
														isRequired
													/>
												</Col>
											</Row>
										</Col>
									</Row>

									<Row>
										<Col sm={12} xl={8}>
											<Label className="fw-bold">{t("FirstInvoice")}</Label>
											<Row>
												<Col xs={7}>
													<TextInput
														htmlId="firstInvoiceDate"
														type="date"
														name="firstInvoiceDate"
														label={t("FirstInvoiceDate")}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.firstInvoiceDate}
														validation={formik}
														invalid={formik.touched.firstInvoiceDate && formik.errors.firstInvoiceDate}
													/>
												</Col>
												<Col xs={5}>
													<Select
														value={invoiceRecipientsEmails}
														isMulti
														onChange={handleRecipientsEmails}
														options={invoiceRecipientsEmailOptions}
														classNamePrefix="select2-selection"
														className="mb-3"
														placeholder={t("SendTo")}
														closeMenuOnSelect={false}
														noOptionsMessage={() => t("NoOptionsMessage")}
														styles={{
															control: (baseStyles, state) => ({
																...baseStyles,
																minHeight: "58px",
															}),
														}}
													/>
												</Col>
											</Row>
											<Row>
												<Col xs={7}>
													<TextInput
														htmlId="minAmount"
														name="minAmount"
														label={t("MinAmount")}
														type="number"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={Number(formik.values.minAmount)}
														validation={formik}
														invalid={formik.touched.minAmount && formik.errors.minAmount}
													/>
												</Col>
												<Col xs={5}>
													<TextInput
														htmlId="minAmountTax"
														name="minAmountTax"
														label={t("Tax")}
														type="number"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={Number(_.clamp(formik.values.minAmountTax, 0, 100)).toString()}
														validation={formik}
														invalid={formik.touched.minAmountTax && formik.errors.minAmountTax}
													/>
												</Col>
											</Row>
											<Row>
												<Col xs={7}>
													<TextInput
														htmlId="maxAmount"
														name="maxAmount"
														label={t("MaxAmount")}
														type="number"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={Number(formik.values.maxAmount).toString()}
														validation={formik}
														invalid={formik.touched.maxAmount && formik.errors.maxAmount}
													/>
												</Col>
												<Col xs={5}>
													<TextInput
														htmlId="maxAmountTax"
														name="maxAmountTax"
														label={t("Tax")}
														type="number"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={Number(_.clamp(formik.values.maxAmountTax, 0, 100)).toString()}
														validation={formik}
														invalid={formik.touched.maxAmountTax && formik.errors.maxAmountTax}
													/>
												</Col>
											</Row>
											<Row>
												<Col xs={7}>
													<TextInput
														htmlId="technicalCost"
														name="technicalCost"
														label={t("TechnicalCost")}
														type="number"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={Number(formik.values.technicalCost).toString()}
														validation={formik}
														invalid={formik.touched.technicalCost && formik.errors.technicalCost}
													/>
												</Col>
												<Col xs={5}>
													<TextInput
														htmlId="technicalCostTax"
														name="technicalCostTax"
														label={t("Tax")}
														type="number"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={Number(_.clamp(formik.values.technicalCostTax, 0, 100)).toString()}
														validation={formik}
														invalid={formik.touched.technicalCostTax && formik.errors.technicalCostTax}
													/>
												</Col>
											</Row>
											<Row>
												<Col xs={7}>
													<TextInput
														htmlId="monthlySubscription"
														name="monthlySubscription"
														label={t("MonthlySubscription")}
														type="number"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={Number(formik.values.monthlySubscription).toString()}
														validation={formik}
														invalid={formik.touched.monthlySubscription && formik.errors.monthlySubscription}
													/>
												</Col>
												<Col xs={5}>
													<TextInput
														htmlId="monthlySubscriptionTax"
														name="monthlySubscriptionTax"
														label={t("Tax")}
														type="number"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={Number(_.clamp(formik.values.monthlySubscriptionTax, 0, 100)).toString()}
														validation={formik}
														invalid={formik.touched.monthlySubscriptionTax && formik.errors.monthlySubscriptionTax}
													/>
												</Col>
											</Row>
											<Row>
												<Col xs={7}>
													<TextInput
														htmlId="directSaleCommission"
														name="directSaleCommission"
														label={t("DirectSaleCommission")}
														type="number"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={_.clamp(Number(formik.values.directSaleCommission), 0, 100).toString()}
														validation={formik}
														invalid={formik.touched.directSaleCommission && formik.errors.directSaleCommission}
													/>
												</Col>
												<Col xs={5}>
													<TextInput
														htmlId="directSaleCommissionTax"
														name="directSaleCommissionTax"
														label={t("Tax")}
														type="number"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={Number(_.clamp(formik.values.directSaleCommissionTax, 0, 100)).toString()}
														validation={formik}
														invalid={formik.touched.directSaleCommissionTax && formik.errors.directSaleCommissionTax}
													/>
												</Col>
											</Row>
											<Row>
												<Col xs={7}>
													<TextInput
														htmlId="onlineSaleCommission"
														name="onlineSaleCommission"
														label={t("OnlineSaleCommission")}
														type="number"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={_.clamp(Number(formik.values.onlineSaleCommission), 0, 100).toString()}
														validation={formik}
														invalid={formik.touched.onlineSaleCommission && formik.errors.onlineSaleCommission}
													/>
												</Col>
												<Col xs={5}>
													<TextInput
														htmlId="onlineSaleCommissionTax"
														name="onlineSaleCommissionTax"
														label={t("Tax")}
														type="number"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={Number(_.clamp(formik.values.onlineSaleCommissionTax, 0, 100)).toString()}
														validation={formik}
														invalid={formik.touched.onlineSaleCommissionTax && formik.errors.onlineSaleCommissionTax}
													/>
												</Col>
											</Row>
											<Row>
												<Col xs={7}>
													<TextInput
														htmlId="marketplaceSaleCommission"
														name="marketplaceSaleCommission"
														label={t("MarketplaceSaleCommission")}
														type="number"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={_.clamp(Number(formik.values.marketplaceSaleCommission), 0, 100).toString()}
														validation={formik}
														invalid={formik.touched.marketplaceSaleCommission && formik.errors.marketplaceSaleCommission}
													/>
												</Col>
												<Col xs={5}>
													<TextInput
														htmlId="marketplaceSaleCommissionTax"
														name="marketplaceSaleCommissionTax"
														label={t("Tax")}
														type="number"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={Number(_.clamp(formik.values.marketplaceSaleCommissionTax, 0, 100)).toString()}
														validation={formik}
														invalid={formik.touched.marketplaceSaleCommissionTax && formik.errors.marketplaceSaleCommissionTax}
													/>
												</Col>
											</Row>
										</Col>
									</Row>

									<Row>
										<Label className="fw-bold">{t("Discounts")}</Label>
										<Col sm={12} md={10}>
											{discounts?.length === 0 ? (
												<p className="text-dark">{t("NoDiscountAvailable")}</p>
											) : (
												discounts?.map((discount, idx) => (
													<Row key={discount?.id || idx}>
														<Col sm={5} md={3}>
															<TextInput
																type="number"
																label={t("Discount")}
																htmlId={`discountValue${idx}`}
																value={Number(discount.value).toString()}
																onChange={(e) => handleChangeDiscount(discount?.id || discount.uuid, "value", Number(e.target.value).toString())}
																className="mb-0"
															/>
														</Col>
														<Col xs={2} className="text-center d-flex align-items-center justify-content-center">
															<ButtonGroup size="lg" className="mb-3">
																<Button
																	color="info"
																	outline
																	onClick={() => handleChangeDiscount(discount?.id || discount.uuid, "unit", TaxUnit.Currency)}
																	active={discount.unit === TaxUnit.Currency}
																>
																	{t("EuroSymbol")}
																</Button>
																<Button
																	color="info"
																	outline
																	onClick={() => handleChangeDiscount(discount?.id || discount.uuid, "unit", TaxUnit.Percentage)}
																	active={discount.unit === TaxUnit.Percentage}
																>
																	{t("PercentageSymbol")}
																</Button>
															</ButtonGroup>
														</Col>
														<Col sm={5} md={4}>
															<TextInput
																htmlId={`discountDate${idx}`}
																type="month"
																name="discountDate"
																label={t("Date")}
																onChange={(e) => handleChangeDiscount(discount?.id || discount.uuid, "date", e.target.value ? new Date(e.target.value) : "")}
																value={discount.date ? moment(discount.date).format("YYYY-MM") : ""}
															/>
														</Col>
													</Row>
												))
											)}
											<Button color="info" onClick={handleAddDiscount}>
												{t("AddDiscount")}
											</Button>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</>
					)}

					<div>
						<Button color="light" className="me-2" onClick={() => history.goBack()}>
							{t("Cancel")}
						</Button>
						<Button
							color="info"
							type="submit"
							disabled={loading}
							onClick={() => {
								if (process.env.REACT_APP_ENV === "development") console.log(formik.errors);
							}}
						>
							{id ? t("UpdateClient") : t("Save")}
						</Button>
					</div>
				</Form>
			</Container>

			<SuccessModal isOpen={openSuccessModal} toggle={toggleSuccessModal} title={t("SuccessModalTitle")} description={t("SuccessModalSubtitle")} />
		</div>
	);
};

export default ClientForm;
