export const menuItems = [
	{
		key: 1,
		icon: 'bxs-user-rectangle',
		title: "MyAccount",
		subtitle: "MyAccountSubTitle",
		path: "authentication/profile",
	},
	{
		key: 2,
		icon: 'bxs-pen',
		title: "PlatformAndCountry",
		subtitle: "PlatformAndCountrySubtitle",
		path: "countries",
	},
	{
		key: 3,
		icon: 'bxs-user-account',
		title: "UsersAccounts",
		subtitle: "UsersAccountsSubtitle",
		path: "userAccount",
	},
];
