import React from "react";
import { Modal, ModalHeader } from "reactstrap";
import LoadingSpinner from "../UI/LoadingSpinner";

const BaseModal = ({ isOpen, toggle, loading, children, ...rest }) => {
	return (
		<Modal isOpen={isOpen} toggle={toggle} unmountOnClose centered fade={false} {...rest}>
			{loading ? (
				<div className="p-3">
					<LoadingSpinner />
				</div>
			) : (
				<React.Fragment>
					<ModalHeader toggle={toggle}></ModalHeader>
					{children}
				</React.Fragment>
			)}
		</Modal>
	);
};

export default BaseModal;
