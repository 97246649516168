import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardTitle, CardText, Button, Container, Input } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import MetaTags from "react-meta-tags";
import { useHistory, useParams } from "react-router-dom";
import { ACCOUNTS_CLIENT, QUOTES_CLIENT } from "../../../api/api_helper";
import { ProfileType } from "../../../api/hotel-api.service";
import LoadingSpinner from "../../../components/UI/LoadingSpinner";
import { useTranslation } from "react-i18next";
import useModal from "../../../hooks/useModal";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import { ResourceContext } from "../../../context";
import SelectInput from "../../../components/Inputs/SelectInput";
import moment from "moment";

const quotesDateFilter = [
	{
		value: "CurrentMonth",
		dates: {
			startDate: moment().startOf("month").toDate(),
			endDate: moment().toDate(),
		},
	},
	{
		value: "LastSevenDays",
		dates: {
			startDate: moment().add(-7, "days").toDate(),
			endDate: moment().toDate(),
		},
	},
	{
		value: "LastThirtyDays",
		dates: {
			startDate: moment().add(-30, "days").toDate(),
			endDate: moment().toDate(),
		},
	},
	{
		value: "LastNinetyDays",
		dates: {
			startDate: moment().add(-90, "days").toDate(),
			endDate: moment().toDate(),
		},
	},
	{
		value: "CurrentYear",
		dates: {
			startDate: moment().startOf("year").toDate(),
			endDate: moment().toDate(),
		},
	},
	{
		value: "ViewAll",
		dates: {
			startDate: null,
			endDate: null,
		},
	},
];

const ClientDetails = () => {
	const { t } = useTranslation();
	const resources = useContext(ResourceContext);
	const [activeTab, setActiveTab] = useState("1");
	const history = useHistory();
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [details, setDetails] = useState();
	const [managerContact, setManagerContact] = useState();
	const [salesAgentContact, setSalesAgentContact] = useState();
	const [statusList, setStatusList] = useState([]);
	const [currentDateFilter, setCurrentDateFilter] = useState("ViewAll");

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	const { open: openDeleteModal, toggle: toggleDeleteModal } = useModal();

	const handleEditClient = () => {
		history.push(`/clients/${id}/edit`);
	};

	const handleDelete = async () => {
		toggleDeleteModal();
		await ACCOUNTS_CLIENT.delete(id);
		history.push("/clients");
	};

	// const handleMandatSignatureRequest = async () => {
	// 	setLoading(true);
	// 	try {
	// 		await ACCOUNTS_CLIENT.sendMandatSignatureRequest(id);
	// 	} catch (error) {
	// 		toastr.error(error);
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };

	const fetchAmountsByStatus = async (rangePeriod) => {
		const dateFilter = quotesDateFilter.find((x) => x.value === rangePeriod);
		let { startDate, endDate } = dateFilter.dates;
		setCurrentDateFilter(dateFilter.value);
		setLoading(true);
		QUOTES_CLIENT.getAmountsByStatus(id, null, null, null, null, null, startDate, endDate)
			.then((response) => setStatusList(response))
			.catch((error) => console.error(error))
			.finally(() => setLoading(false));
	};

	const handleRangeTimeChange = (e) => {
		const rangePeriod = e.target.value;
		fetchAmountsByStatus(rangePeriod);
	};

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			try {
				const hotel = await ACCOUNTS_CLIENT.getById(id);
				setDetails(hotel);
				setManagerContact(hotel.contacts.find((e) => e.profile === ProfileType.Manager));
				setSalesAgentContact(hotel.contacts.find((e) => e.profile === ProfileType.SalesAgent));

				const statuses = await QUOTES_CLIENT.getAmountsByStatus(id);
				setStatusList(statuses);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};
		fetch();
	}, []);

	return (
		<div className="page-content">
			<MetaTags>
				<title>{t("Clients")} | Quotelo</title>
			</MetaTags>

			<Breadcrumbs title={t("ClientsManagement")} breadcrumbItem={details?.title} link="/clients" />

			<Container fluid>
				<Row>
					<Col xs="12">
						<Row className="mb-4">
							<div className="d-flex justify-content-end">
								{/* <Button color="info me-2" onClick={handleMandatSignatureRequest}>
									{t("MandatSignatureRequest")}
								</Button> */}
								<Button color="info" onClick={handleEditClient}>
									{t("Edit")}
								</Button>
								<Button color="transparent" className="text-danger" onClick={toggleDeleteModal}>
									<i className="bx bx-trash fs-4"></i>
								</Button>
							</div>
						</Row>

						<Nav className="justify-content-center nav-pills mb-4">
							<NavItem>
								<NavLink
									className={activeTab === "1" ? "active bg-info" : "bg-light"}
									onClick={() => {
										toggle("1");
									}}
									disabled={loading}
								>
									{t("Hotel")}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={activeTab === "2" ? "active bg-info" : "bg-light"}
									onClick={() => {
										toggle("2");
									}}
									disabled={loading}
								>
									{t("Billing")}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={activeTab === "3" ? "active bg-info" : "bg-light"}
									onClick={() => {
										toggle("3");
									}}
									disabled={loading}
								>
									{t("ActivityTracking")}
								</NavLink>
							</NavItem>
						</Nav>

						{loading ? (
							<LoadingSpinner />
						) : (
							<TabContent activeTab={activeTab}>
								<TabPane tabId="1">
									<Row>
										<Row>
											<Col sm="4">
												<Card body>
													<CardTitle>{t("Hotel")}</CardTitle>
													<CardText className="text-info">{t("Address")}</CardText>
													<CardText>{[details?.address, details?.zipCode, details?.city, details?.country].filter(Boolean).join(", ")}</CardText>
													<CardText className="text-info">{t("Reception")}</CardText>
													<CardText>{details?.receptionPhoneNumber || "---"}</CardText>
													<CardText>{details?.receptionEmail} </CardText>
													<CardText className="text-info">{t("CommercialService")}</CardText>
													<CardText>{details?.salesDepartmentPhoneNumber || "---"}</CardText>
													<CardText>{details?.salesDepartmentEmail || "---"}</CardText>
												</Card>
											</Col>
											<Col sm="4">
												<Card body>
													<CardTitle>{t("Manager")}</CardTitle>
													<CardText className="text-info">{t("Name")}</CardText>
													<CardText>{`${managerContact?.firstName} ${managerContact?.lastName}`}</CardText>
													<CardText className="text-info">{t("PhoneNumber")}</CardText>
													<CardText>{managerContact?.phoneNumber}</CardText>
													<CardText className="text-info">{t("Email")}</CardText>
													<CardText>{managerContact?.email}</CardText>
												</Card>
											</Col>
											<Col sm="4">
												<Card body>
													<CardTitle>{t("SalesAgent")}</CardTitle>
													<CardText className="text-info">{t("Name")}</CardText>
													<CardText>{`${salesAgentContact?.firstName || ""} ${salesAgentContact?.lastName || ""}`}</CardText>
													<CardText className="text-info">{t("PhoneNumber")}</CardText>
													<CardText>{salesAgentContact?.phoneNumber || "---"}</CardText>
													<CardText className="text-info">{t("Email")}</CardText>
													<CardText>{salesAgentContact?.email || "---"}</CardText>
												</Card>
											</Col>
										</Row>
									</Row>
								</TabPane>
								<TabPane tabId="2">
									<Row>
										<Col sm="12">
											<Card body>
												<CardTitle>{t("Billing")}</CardTitle>
												<Row>
													<Col sm="3">
														<CardText className="text-info">{t("CompanyName2")}</CardText>
														<CardText>{details?.billingSetting?.companyName}</CardText>
														<CardText className="text-info">{t("InvoiceRecipient")}</CardText>
														<CardText>{`${details?.billingSetting?.firstName || ""} ${details?.billingSetting?.lastName || ""}`}</CardText>
													</Col>
													<Col sm="3">
														<CardText className="text-info">{t("SiretNumber")}</CardText>
														<CardText>{details?.billingSetting?.siretNumber}</CardText>
														<CardText className="text-info">{t("BankName")}</CardText>
														<CardText>{details?.billingSetting?.bankName}</CardText>
													</Col>
													<Col sm="3">
														<CardText className="text-info">{t("RcsNumber")}</CardText>
														<CardText>{details?.billingSetting?.rcsNumber}</CardText>
														<CardText className="text-info">{t("IbanNumber")}</CardText>
														<CardText>{details?.billingSetting?.ibanNumber}</CardText>
													</Col>
													<Col sm="3">
														<CardText className="text-info">{t("TvaNumber")}</CardText>
														<CardText>{details?.billingSetting?.tvaNumber}</CardText>
														<CardText className="text-info">{t("BicNumber")}</CardText>
														<CardText>{details?.billingSetting?.bicNumber}</CardText>
													</Col>
												</Row>
											</Card>
										</Col>
									</Row>
								</TabPane>
								<TabPane tabId="3">
									<Row>
										<Col sm={5}>
											<Card body>
												<div className="col-md-6">
													<SelectInput
														label={t("RangeTime")}
														id="RangeTime"
														value={currentDateFilter}
														options={quotesDateFilter.map((x) => ({ value: x.value, label: t(x.value) }))}
														onChange={handleRangeTimeChange}
													/>
												</div>
												<CardTitle>{t("QuotesCountByStatus")}</CardTitle>
												{loading ? (
													<LoadingSpinner />
												) : (
													statusList?.map((status) => {
														return (
															<CardText key={status.quoteStatusType}>
																<span className="text-info">{resources.QuoteStatusType[status.quoteStatusType]} : </span>
																{status.quotesCount}
															</CardText>
														);
													})
												)}
											</Card>
										</Col>
									</Row>
								</TabPane>
							</TabContent>
						)}
					</Col>
				</Row>
			</Container>

			<ConfirmationModal
				title={t("ConfirmDeleteHotelTitle")}
				description={t("ConfirmDeleteHotelDescription")}
				isOpen={openDeleteModal}
				toggle={toggleDeleteModal}
				action={handleDelete}
				loading={loading}
				color="danger"
			/>
		</div>
	);
};

export default ClientDetails;
