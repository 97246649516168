import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Button, ModalBody, Form, Badge } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { USERS_CLIENT } from "../../../api/api_helper";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import Pagination from "../../../components/UI/Pagination";
import LoadingSpinner from "../../../components/UI/LoadingSpinner";
import useModal from "../../../hooks/useModal";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import BaseModal from "../../../components/Modals/BaseModal";
import ModalTitle from "../../../components/Modals/ModalTitle";
import ModalText from "../../../components/Modals/ModalText";
import BaseModalFooter from "../../../components/Modals/BaseModalFooter";
import TextInput from "../../../components/Inputs/TextInput";
import SelectInput from "../../../components/Inputs/SelectInput";
import SuccessModal from "../../../components/Modals/SuccessModal";

const UsersList = () => {
	const { t } = useTranslation();
	const [users, setUsers] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageCount, setPageCount] = useState(1);
	const [loading, setLoading] = useState(false);
	const sizePerPage = 10;
	const [userToDelete, setUserToDelete] = useState({});
	const { open: openDeleteModal, toggle: toggleDeleteModal } = useModal();
	const { open: openAddUserModal, toggle: toggleAddUserModal } = useModal();
	const { open: openSuccessModal, toggle: toggleSuccessModal } = useModal();

	const roleNameOptions = [
		{ value: "SuperAdmin", label: "Super Admin" },
		// { value: "CSM", label: "CSM" },
	];

	const columns = [
		{
			text: t("UserType"),
			dataField: "roleNames",
			isDummyField: true,
			formatter: (cell, row) => (
				<Badge color="info" pill className="bg-opacity-75 px-2 py-1">
					{t(row.roleNames?.find(Boolean))}
				</Badge>
			),
		},
		{
			text: t("LastName"),
			dataField: "fullName",
			isDummyField: true,
			formatter: (cell, row) => `${row?.firstName || ""} ${row?.lastName || ""}`,
		},
		{
			text: t("Username"),
			dataField: "userName",
		},
		{
			text: t("Email"),
			dataField: "email",
		},
		{
			text: t("CreatedDate"),
			dataField: "created",
			isDummyField: true,
			formatter: (cell, row) => row.created.toLocaleDateString(),
		},
		{
			text: "",
			dataField: "deleteButton",
			isDummyField: true,
			formatter: (cell, row) => (
				<button onClick={() => onClickDelete(row)} color="danger" className="bg-transparent text-danger border-0">
					<i className="bx bx-trash fs-4" id="deletetooltip"></i>
				</button>
			),
		},
	];

	const formik = useFormik({
		validateOnChange: true,
		validateOnBlur: true,
		initialValues: {
			roleName: roleNameOptions.find(Boolean).value, // TODO: Hassan said this will become a simple string
			firstName: "",
			lastName: "",
			email: "",
			phoneNumber: "",
		},
		validationSchema: Yup.object().shape({
			roleName: Yup.string().required(t("RequiredFieldInputError")),
			firstName: Yup.string().required(t("RequiredFieldInputError")),
			lastName: Yup.string().required(t("RequiredFieldInputError")),
			email: Yup.string().required(t("RequiredFieldInputError")).email(t("InvalidEmailFormat")),
			phoneNumber: Yup.string()
				.required(t("RequiredFieldInputError"))
				.matches(/^\d[\s\d]+$/i, t("InvalidPhoneNumber")),
		}),
		onSubmit: async (values) => {
			setLoading(true);
			try {
				await USERS_CLIENT.create(values);
				toggleAddUserModal();
				fetchUsers();
				toggleSuccessModal();
			} catch (error) {
				// toastr.error(error?.message);
			} finally {
				setLoading(false);
			}
		},
	});

	const handlePagination = (page) => {
		setCurrentPage(page);
	};

	const fetchUsers = (page = 1) => {
		USERS_CLIENT.get(page, sizePerPage)
			.then((response) => {
				setUsers(response.results);
				setPageCount(response.pageCount);
			})
			.catch((error) => console.log(error));
	};

	const onClickDelete = (row) => {
		setUserToDelete(row);
		toggleDeleteModal();
	};

	const handleDeleteUser = () => {
		USERS_CLIENT.delete(userToDelete.id)
			.then(() => {
				setUsers((prev) => prev?.filter((x) => x?.id != userToDelete.id));
				setUserToDelete({});
				toggleDeleteModal();
			})
			.catch((error) => console.log(error));
	};

	useEffect(() => {
		fetchUsers(currentPage);
	}, [currentPage]);

	useEffect(() => {
		if (!openAddUserModal) {
			formik.resetForm();
		}
	}, [openAddUserModal]);

	return (
		<div className="page-content">
			<MetaTags>
				<title>{t("Users")} | Quotelo</title>
			</MetaTags>

			<Breadcrumbs title={t("Settings")} breadcrumbItem={t("UsersAccounts")} link="/settings" />

			<Container fluid>
				<Row className="mb-4">
					<div className="d-flex justify-content-end">
						<Button color="info" onClick={toggleAddUserModal}>
							<i className="bx bx-plus" />
							<span>{t("AddNewUser")}</span>
						</Button>
					</div>
				</Row>
				<Row>
					<Col lg="12">
						<Card>
							<CardBody>
								<Col xl="12">
									<div className="table-responsive">
										<BootstrapTable keyField="id" data={users} columns={columns} bootstrap4 classes={"table align-middle table-nowrap table-hover"} />

										<Pagination currentPage={currentPage} pageCount={pageCount} onChange={handlePagination} />
									</div>
								</Col>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>

			<ConfirmationModal
				title={t("DeleteUserAccountTitle")}
				description={t("DeleteUserAccountDescription")}
				isOpen={openDeleteModal}
				toggle={toggleDeleteModal}
				action={handleDeleteUser}
				loading={loading}
				color="danger"
			/>

			<SuccessModal isOpen={openSuccessModal} toggle={toggleSuccessModal} title={t("SuccessModalTitle")} description={t("UserCreatedSuccessfully")} />

			{openAddUserModal && (
				<BaseModal isOpen={openAddUserModal} toggle={toggleAddUserModal}>
					<ModalBody>
						<ModalTitle title={t("AddNewUser")} />
						<ModalText>{t("AddNewUserDescription")}</ModalText>
						<Form id="newUserForm" className="need-validation" onSubmit={formik.handleSubmit}>
							{loading ? (
								<Card>
									<CardBody>
										<LoadingSpinner />
									</CardBody>
								</Card>
							) : (
								<>
									<Row>
										<Col xs={12}>
											<SelectInput
												label={t("RoleName")}
												id="roleName"
												name="roleName"
												value="SuperAdmin"
												options={roleNameOptions}
												onChange={formik.handleChange}
												validation={formik}
												invalid={formik.touched.roleName && formik.errors.roleName}
												isRequired
											/>
										</Col>
									</Row>

									<Row>
										<Col md={6}>
											<TextInput
												htmlId="firstName"
												name="firstName"
												label={t("FirstName")}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.firstName}
												validation={formik}
												invalid={formik.touched.firstName && formik.errors.firstName}
												isRequired
											/>
										</Col>

										<Col md={6}>
											<TextInput
												htmlId="lastName"
												name="lastName"
												label={t("LastName")}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.lastName}
												validation={formik}
												invalid={formik.touched.lastName && formik.errors.lastName}
												isRequired
											/>
										</Col>
									</Row>

									<Row>
										<Col xs={12}>
											<TextInput
												htmlId="email"
												name="email"
												label={t("Email")}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.email}
												validation={formik}
												invalid={formik.touched.email && formik.errors.email}
												isRequired
											/>
										</Col>
									</Row>

									<Row>
										<Col xs={12}>
											<TextInput
												htmlId="phoneNumber"
												name="phoneNumber"
												label={t("PhoneNumber")}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.phoneNumber}
												validation={formik}
												invalid={formik.touched.phoneNumber && formik.errors.phoneNumber}
												isRequired
											/>
										</Col>
									</Row>
								</>
							)}
						</Form>
					</ModalBody>
					<BaseModalFooter toggle={toggleAddUserModal}>
						<Button color="info" type="submit" form="newUserForm">
							{t("Add")}
						</Button>
					</BaseModalFooter>
				</BaseModal>
			)}
		</div>
	);
};

export default UsersList;
