import React from "react";
import MetaTags from "react-meta-tags";
import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import { useHistory } from "react-router-dom";
import { menuItems } from "./settingsMenuItems";

const SettingsMenu = () => {
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<div className="page-content">
			<MetaTags>
				<title>{t("Settings")}</title>
			</MetaTags>
			<div className="row mb-4">
				<h2 className="mx-5">{t("Settings")}</h2>
			</div>
			<div className="row">
				{menuItems.map((item) => {
					return (
						<div key={item.key} className="col-sm-12 col-md-4">
							<div className="mx-3">
								<Card
									body
									className={`text-center p-4 menu-card ${item.disabled ? "opacity-50" : ""}`}
									onClick={() => !item.disabled && history.push("/" + item.path)}
									style={{ cursor: "pointer" }}
								>
									<div className="menu-card-text menu-card-icon d-flex align-items-center justify-content-center" style={{ height: 45 }}>
										<i className={`bx ${item.icon} fs-1 text-primary`} />
									</div>
									<span className="fw-bold mt-3 mb-1">{t(item.title)}</span>
									<span>{t(item.subtitle)}</span>
								</Card>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default SettingsMenu;
