import React, { useEffect, useState } from "react";
import BaseModal from "../../../components/Modals/BaseModal";
import { Button, ModalBody } from "reactstrap";
import ModalTitle from "../../../components/Modals/ModalTitle";
import BaseModalFooter from "../../../components/Modals/BaseModalFooter";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ACCOUNTS_CLIENT } from "../../../api/api_helper";
import LoadingSpinner from "../../../components/UI/LoadingSpinner";

function ExportUsersModal({ isOpen, toggle, action, loading }) {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState([]);
	const [hotels, setHotels] = useState([]);
	const [selectedHotel, setSelectHotel] = useState(null);

	const handleChange = (hotel) => {
		setSelectHotel(hotel);
	};

	useEffect(() => {
		const fetch = async () => {
			setIsLoading(true);
			try {
				const { results } = await ACCOUNTS_CLIENT.search();
				setHotels([{ id: null, title: t("AllHotels") }, ...results]);
			} catch (error) {
				console.log(error);
			} finally {
				setIsLoading(false);
			}
		};
		fetch();
	}, []);

	return (
		<BaseModal isOpen={isOpen} toggle={toggle} loading={loading || isLoading}>
			<ModalBody>
				{loading ? (
					<LoadingSpinner />
				) : (
					<>
						<ModalTitle title={t("ExportUsers")} />
						<div className="d-flex justify-content-center align-items-center w-100">
							<Select
								options={hotels}
								value={selectedHotel}
								onChange={handleChange}
								getOptionLabel={(option) => option.title}
								getOptionValue={(option) => option.id}
								placeholder={t("SelectHotel")}
								noOptionsMessage={() => t("NoOptionsMessage")}
								className="w-75"
							/>
						</div>
					</>
				)}
			</ModalBody>
			<BaseModalFooter toggle={toggle}>
				<Button color="info" onClick={() => action(selectedHotel?.id)} disabled={!selectedHotel?.title}>
					{t("Confirm")}
				</Button>
			</BaseModalFooter>
		</BaseModal>
	);
}

export default ExportUsersModal;
