import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, ModalBody } from "reactstrap";
import TextInput from "../../../components/Inputs/TextInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import ModalTitle from "../../../components/Modals/ModalTitle";
import BaseModal from "../../../components/Modals/BaseModal";
import BaseModalFooter from "../../../components/Modals/BaseModalFooter";
import { COUNTRIES_CLIENT } from "../../../api/api_helper";
import { useHistory } from "react-router-dom";
import toastr from "toastr";
import Select from "react-select";
import { countryLocales } from "../../../helpers/countryLocales";
import { regionNames } from "../../../helpers/intl";

function AddCountryModal({ open, toggle }) {
	const { t } = useTranslation();
	const history = useHistory();
	const [loading, setLoading] = useState(false);

	const formik = useFormik({
		initialValues: {
			name: "",
			code: "",
			locale: "",
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required(t("RequiredFieldInputError")),
		}),
		onSubmit: async (values) => {
			setLoading(true);
			try {
				const newCountry = await COUNTRIES_CLIENT.create(values);
				history.push(`/countries/${newCountry.id}`);
			} catch (error) {
				toastr.error(error);
			} finally {
				setLoading(false);
				toggle();
			}
		},
	});

	const handleCountryChange = (country) => {
		const { country: code, en_name: name, locales } = country;
		formik.setValues({
			name,
			code,
			locale: locales.split(",")[0].trim(),
		});
	};

	return (
		<BaseModal isOpen={open} toggle={toggle} loading={loading}>
			<form onSubmit={formik.handleSubmit}>
				<ModalBody>
					<ModalTitle title={t("AddCountry")} />

					<Select
						options={countryLocales}
						getOptionLabel={(option) => regionNames.of(option.country)}
						getOptionValue={(option) => option.country}
						value={formik.code}
						onChange={(newCountry) => handleCountryChange(newCountry)}
					/>
				</ModalBody>
				<BaseModalFooter toggle={toggle}>
					<Button color="info" type="submit">
						{t("Confirm")}
					</Button>
				</BaseModalFooter>
			</form>
		</BaseModal>
	);
}

export default AddCountryModal;
