import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// Import components
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import ResourceContextProvider from "./context";
import Authmiddleware from "./routes/route";
import { setupAxiosApi } from "./api/api_helper";
import LoadingSpinner from "./components/UI/LoadingSpinner";

toastr.options = {
	positionClass: "toast-bottom-right",
	toastClass: "toastr",
};

const App = (props) => {
	const Layout = VerticalLayout;

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const setup = async () => {
			setLoading(true);
			try {
				await setupAxiosApi();
			} catch (error) {
			} finally {
				setLoading(false);
			}
		};
		setup();
	}, []);

	return (
		<ResourceContextProvider>
			{loading ? (
				<LoadingSpinner />
			) : (
				<Router>
					<Switch>
						{publicRoutes.map((route, idx) => (
							<Authmiddleware path={route.path} layout={NonAuthLayout} component={route.component} key={idx} isAuthProtected={false} exact />
						))}
						<Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
						{authProtectedRoutes.map((route, idx) => (
							<AuthorizeRoute path={route.path} layout={Layout} component={route.component} key={idx} isAuthProtected={true} exact />
						))}
					</Switch>
				</Router>
			)}
		</ResourceContextProvider>
	);
};

App.propTypes = {
	layout: PropTypes.any,
};

const mapStateToProps = (state) => {
	return {
		layout: state.Layout,
	};
};

export default connect(mapStateToProps, null)(App);
