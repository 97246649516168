import { useState } from "react";

const useModal = () => {
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const toggle = () => {
		setOpen((prev) => !prev);
	};

	const toggleLoading = () => {
		setLoading(!loading);
		if (loading === false) {
			toggle();
		}
	};

	return { open, toggle, loading, toggleLoading };
};

export default useModal;
