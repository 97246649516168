import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import {ApplicationPaths} from "../components/api-authorization/ApiAuthorizationConstants";
import authService  from "../components/api-authorization/AuthorizeService";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !authService.isAuthenticated()) {
        return (
          <Redirect
            to={{ pathname: ApplicationPaths.Login, state: { from: props.location } }}
          />
        )
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware;
