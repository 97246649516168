import React from "react";
import { Card, CardBody, Form } from "reactstrap";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";

const FileInput = ({ file, label, documentType, inputWidth, inputWidthMin, disabled, uploadFiles, updateFiles, deleteFiles, docType, isQuoteDocument }) => {
	const DOCUMENTS_API_URL = isQuoteDocument ? process.env.REACT_APP_QUOTES_API_URL : process.env.REACT_APP_DOCUMENTS_API_URL;
	const { t } = useTranslation();

	const handleAcceptedFiles = (file) => {
		uploadFiles(file, documentType);
	};

	const handleUpdateFiles = (file, fileId) => {
		updateFiles(file, fileId, documentType);
	};

	const handleDeleteFiles = (fileId) => {
		deleteFiles(fileId);
	};

	const handleDownloadFile = (fileId) => {
		window.open(DOCUMENTS_API_URL + "/documents/download/" + documentType + "/" + fileId, "_self");
	};

	return (
		<Card className="mb-0" style={{ boxShadow: "none" }}>
			<CardBody className="p-0">
				<div className="row fw-bold mb-2">
					<span>{label}</span>
				</div>
				<div className={`d-flex align-items-center flex-wrap ${disabled ? "opacity-50 pe-none" : ""}`}>
					{!file && (
						<Dropzone
							multiple={false}
							className={!disabled ? "d-none" : "card-img-overlay picture-overlay"}
							onDrop={(acceptedFile) => handleAcceptedFiles(acceptedFile)}
						>
							{({ getRootProps, getInputProps }) => (
								<div
									className={"dropzone bg-info bg-opacity-25 border-primary align-items-center d-flex mb-1 me-1"}
									style={{
										width: inputWidth || "180px",
										minWidth: inputWidthMin || "180px",
										height: "120px",
										minHeight: "120px",
									}}
								>
									<div className="dz-message needsclick p-1" {...getRootProps()}>
										<input {...getInputProps()} accept={docType || ".txt, .ttf, .otf"} disabled={disabled} />
										<div>{file ? <i className="text-info bx bx-upload fs-1" /> : <i className="text-info bx bx-upload fs-1" />}</div>
										<span className="text-info fs-6 fw-normal">{file ? file.uri || t("UpdateFile") : t("AddFile")}</span>
									</div>
								</div>
							)}
						</Dropzone>
					)}
					{file && (
						<Dropzone multiple={false} className="card-img-overlay picture-overlay" onDrop={(acceptedFile) => handleUpdateFiles(acceptedFile, file.id)}>
							{({ getRootProps, getInputProps }) => (
								<div
									// {...getRootProps()}
									onClick={(e) => e.stopPropagation()}
									className={"dropzone bg-light bg-opacity-25 border-info d-flex align-items-center"}
									style={{
										width: inputWidth || "180px",
										minWidth: inputWidthMin || "180px",
										height: "120px",
										minHeight: "120px",
									}}
								>
									<div className="dz-message needsclick p-1">
										<div className="input-group h-100 align-items-center justify-content-center">
											<input id={file.id + "-documentId"} {...getInputProps()} accept={docType || ".txt, .ttf, .otf"} />
											<label
												htmlFor={file.id + "-documentId"}
												className="input-group-text bg-transparent form-label rounded border border-info border-2 text-info cursor-pointer me-1"
											>
												<i className="bx bx-edit-alt fs-5"></i>
											</label>
											<label
												className="input-group-text bg-transparent form-label rounded border border-info border-2 text-info cursor-pointer me-1"
												onClick={() => handleDownloadFile(file.id)}
											>
												<i className="bx bx-download fs-5"></i>
											</label>
											<label
												className="input-group-text bg-transparent form-label rounded border border-2 border-danger text-danger cursor-pointer"
												onClick={() => handleDeleteFiles(file.id)}
											>
												<i className="bx bx-x fs-5"></i>
											</label>
										</div>
										<span className="text-info fs-6 fw-normal">{file.uri || t("UpdateFile")}</span>
									</div>
								</div>
							)}
						</Dropzone>
					)}
				</div>
			</CardBody>
		</Card>
	);
};

export default FileInput;
