export const ApplicationName = 'ACG.Quotelo.Web.QuotationClient';

export const QueryParameterNames = {
    ReturnUrl: 'returnUrl',
    Message: 'message'
};

export const LogoutActions = {
    LogoutCallback: 'logout-callback',
    Logout: 'logout',
    LoggedOut: 'logged-out'
};

export const LoginActions = {
    Login: 'login',
    LoginCallback: 'login-callback',
    LoginFailed: 'login-failed',
    Profile: 'profile',
    Register: 'register'
};

const prefix = `/authentication`;
const clientPrefix = `${window.location.origin}${prefix}`

export const IdentityConfig = {
    authority: process.env.REACT_APP_IDENTITY_SERVER_URL,
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: `${clientPrefix}/${LoginActions.LoginCallback}`,
    front_channel_logout_uri: `${clientPrefix}/${LogoutActions.Logout}`,
    post_logout_redirect_uri: `${clientPrefix}/${LogoutActions.LogoutCallback}`,
    response_type: "code",
    scope: "openid profile email roles quotelo",
};

export const ApplicationPaths = {
    DefaultLoginRedirectPath: '/',
    ApiAuthorizationClientConfigurationUrl: `${IdentityConfig.authority}/_configuration/${ApplicationName}`,
    ApiAuthorizationPrefix: prefix,
    Login: `${prefix}/${LoginActions.Login}`,
    LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
    LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
    Register: `${prefix}/${LoginActions.Register}`,
    Profile: `${prefix}/${LoginActions.Profile}`,
    LogOut: `${prefix}/${LogoutActions.Logout}`,
    LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
    LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
    IdentityRegisterPath: `${IdentityConfig.authority}/Identity/Account/Register`,
    IdentityManagePath: `${IdentityConfig.authority}/Identity/Account/Manage`
};
