import React, { createContext, useEffect, useState } from "react";
import { RESOURCES_CLIENT } from "../api/api_helper";

export const ResourceContext = createContext();

const ResourceContextProvider = (props) => {
	const [resources, setResources] = useState(null);

	useEffect(() => {
		RESOURCES_CLIENT.get().then((response) => setResources(response));
	}, []);

	return resources ? <ResourceContext.Provider value={resources}>{props.children}</ResourceContext.Provider> : null;
};

export default ResourceContextProvider;
