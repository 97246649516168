import React from "react";
import Select, { components } from "react-select";
import { FormFeedback } from "reactstrap";

const ControlComponent = (props) => (
	<components.Control {...props} className="position-relative">
		<label
			className="text-info opacity-100"
			style={{
				position: "absolute",
				top: 0,
				left: 0,
				fontSize: 9.35,
				width: "100%",
				marginBottom: "0.5rem",
				paddingLeft: 14,
				paddingTop: 6,
				// transform: "scale(0.85) ",
			}}
		>
			<span style={{ transform: "scale(0.85) " }}>{props.selectProps.label}</span>
		</label>
		{props.children}
	</components.Control>
);

function FloatingSelectInput({ label, validation, ...props }) {
	return (
		<>
			<Select
				{...props}
				label={label}
				components={{
					Control: ControlComponent,
					IndicatorSeparator: null,
				}}
				styles={{
					control: (baseStyles, state) => ({
						...baseStyles,
						padding: "26px 12px 10px",
						fontSize: "0.8125rem",
						fontWeight: 400,
						lineHeight: 1.5,
						backgroundColor: state.isDisabled ? "#eff2f7" : undefined,
						border: "1px solid #ced4da",
						appearance: "none",
						borderRadius: "0.25rem",
						transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
						height: "auto",
					}),
					valueContainer: (baseStyles, state) => ({
						...baseStyles,
						height: "20.667px",
						padding: 0,
						display: "flex",
						alignItems: "center",
						opacity: 1,
					}),
					singleValue: () => ({
						color: "#495057",
					}),
					dropdownIndicator: (baseStyles, state) => ({
						...baseStyles,
						height: "100%",
						padding: 0,
					}),
					menu: (baseStyles, state) => ({
						...baseStyles,
						zIndex: 5,
					}),
				}}
				className="mb-3"
			/>
			<FormFeedback className="d-block">{validation?.errors?.[props.name] ?? ""}</FormFeedback>
		</>
	);
}

export default FloatingSelectInput;
