import React from "react";
import { ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import illustration from "../../assets/images/SuccessIllustation.svg";
import BaseModal from "./BaseModal";
import ModalTitle from "./ModalTitle";
import ModalText from "./ModalText";
import BaseModalFooter from "./BaseModalFooter";

const SuccessModal = ({ isOpen, toggle, loading, title, description, customButton, ...rest }) => {
	const { t } = useTranslation();

	return (
		<BaseModal isOpen={isOpen} toggle={toggle} loading={loading} {...rest}>
			<ModalBody className="text-center">
				<img src={illustration} alt="success-icon" height={160} className="m-3" />
				<ModalTitle title={title} />
				<ModalText>{description}</ModalText>
			</ModalBody>
			<BaseModalFooter toggle={toggle}>{customButton || <></>}</BaseModalFooter>
		</BaseModal>
	);
};

export default SuccessModal;
