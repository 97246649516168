import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ModalFooter } from "reactstrap";

const BaseModalFooter = ({ children, toggle, hideClose }) => {
	const { t } = useTranslation();
	return (
		<ModalFooter className="justify-content-center">
			{!hideClose && (
				<Button color="light" onClick={toggle}>
					{t("Close")}
				</Button>
			)}
			{children}
		</ModalFooter>
	);
};

export default BaseModalFooter;
