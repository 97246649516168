import React from "react";
import { Redirect } from "react-router-dom";
import ClientsList from "../pages/Clients/Listing/ClientsList";
import ClientDetails from "../pages/Clients/Details/ClientDetails";
import UsersList from "../pages/Settings/Users/UsersList";
import ClientForm from "../pages/Clients/Details/ClientForm";
import SettingsMenu from "../pages/Settings/SettingsMenu";
import Countries from "../pages/Settings/Countries/Countries";
import CountryForm from "../pages/Settings/Countries/CountryForm";

const authProtectedRoutes = [
	{ path: "/clients", component: ClientsList },
	{ path: "/clients/register-client", component: ClientForm },
	{ path: "/clients/:id", component: ClientDetails },
	{ path: "/clients/:id/edit", component: () => <ClientForm editMode /> },
	{ path: "/settings", component: SettingsMenu },
	{ path: "/userAccount", component: UsersList },
	{ path: "/countries", component: Countries },
	{ path: "/countries/:id", component: CountryForm },

	// Catch undefined Routes
	{ path: "", component: () => <Redirect to="/clients" /> },
];

const publicRoutes = [];

export { publicRoutes, authProtectedRoutes };
