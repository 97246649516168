import React from "react";
import { FormFeedback, FormGroup, Label } from "reactstrap";

const SelectInput = ({ label, id, name, value, options, isRequired, onChange, validation, invalid }) => {
	return (
		<FormGroup floating>
			<select id={id} className={`form-select ${invalid ? "is-invalid" : ""}`} onChange={onChange} defaultValue={value}>
				{options.map((item) => (
					<option value={item.value} key={item.value}>
						{item.label}
					</option>
				))}
			</select>
			<Label htmlFor={id} className={`${invalid ? "text-danger" : "text-info"} opacity-100`} style={{ fontSize: 11 }}>
				{label}
				{isRequired && "*"}
			</Label>
			<FormFeedback type="invalid">{invalid ? validation.errors?.[name] : ""}</FormFeedback>
		</FormGroup>
	);
};

export default SelectInput;
