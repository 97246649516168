import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Input, Button } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { ACCOUNTS_CLIENT, HOTELS_CLIENT, QUOTES_CLIENT } from "../../../api/api_helper";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../../components/UI/LoadingSpinner";
import ExportCsvModal from "./ExportCsvModal";
import useModal from "../../../hooks/useModal";
import downloadBlob from "../../../helpers/downloadBlob";
import toastr from "toastr";
import ExportUsersModal from "./ExportUsersModal";
import paginationFactory from "react-bootstrap-table2-paginator";

const defaultSort = {
	sortASCQuotePendingSignature: null,
	sortASCQuoteApproved: null,
};

const ClientsList = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const [hotels, setHotels] = useState([]);
	const [loading, setLoading] = useState();
	const [keyword, setKeyword] = useState(null);
	const [sortOrder, setSortOrder] = useState(defaultSort);
	const defaultSorted = [
		{
			dataField: "id",
			order: "desc",
		},
	];
	const { open: csvModal, toggle: toggleCsvModal } = useModal();
	const { open: exportUsersModal, toggle: toggleExportUsersModal } = useModal();
	const [isDownloading, setIsDownloading] = useState();

	// order: boolean | null, true -> asc false -> desc
	const sortCaretStyle = (order) => () => {
		if (order === null) return <i className="bx bx-sort ms-1 font-size-16"></i>;
		else if (order) return <i className="bx bx-sort-z-a ms-1 font-size-16"></i>;
		else return <i className="bx bx-sort-a-z ms-1 font-size-16"></i>;
	};

	const columns = [
		{
			text: t("Client"),
			dataField: "title",
		},
		{
			text: t("Contact"),
			dataField: "fullNameManager",
		},
		{
			text: t("E-mail"),
			dataField: "email",
		},
		{
			text: t("ReceptionPhoneNumber"),
			dataField: "phoneNumber",
			isDummyField: true,
			formatter: (cell, row) => row.phoneNumber || "",
		},
		{
			text: t("Localisation"),
			dataField: "localization",
			isDummyField: true,
			formatter: (cell, row) => [row.city, row.country].filter((e) => e).join(", "),
		},
		{
			text: t("LatestQuotesPendingSignature"),
			dataField: "nbrQuotePendingSignature",
			sort: true,
			sortCaret: sortCaretStyle(sortOrder.sortASCQuotePendingSignature),
			sortFunc: () => null,
			onSort: (field) => {
				setSortOrder((prev) => ({
					sortASCQuotePendingSignature: !!!prev.sortASCQuotePendingSignature,
					sortASCQuoteApproved: null,
				}));
			},
		},
		{
			text: t("LatestQuotesApproved"),
			dataField: "nbrQuoteApproved",
			sort: true,
			sortCaret: sortCaretStyle(sortOrder.sortASCQuoteApproved),
			sortFunc: () => null,
			onSort: (field) => {
				setSortOrder((prev) => ({
					sortASCQuoteApproved: !!!prev.sortASCQuoteApproved,
					sortASCQuotePendingSignature: null,
				}));
			},
		},
	];

	const rowEvents = {
		onClick: (e, row, rowIndex) => {
			history.push(`/clients/${row.id}`);
		},
	};

	const paginationOptions = {
		sizePerPage: 15,
		showTotal: false,
		hideSizePerPage: true,
	};

	const handleKeywordChange = (e) => {
		setKeyword(e.target.value);
	};

	const fetchHotels = () => {
		setLoading(true);
		ACCOUNTS_CLIENT.search(keyword, sortOrder.sortASCQuotePendingSignature, sortOrder.sortASCQuoteApproved)
			.then((response) => {
				setHotels(response.sort((a, b) => new Date(b.created) - new Date(a.created)));
			})
			.catch((error) => console.log(error))
			.finally(() => setLoading(false));
	};

	const exportCsv = async (monthYear) => {
		if (monthYear) {
			const [year, month] = monthYear.split("-");
			setIsDownloading(true);
			try {
				const response = await QUOTES_CLIENT.buildCSVForQuote(month, year);
				downloadBlob(response.data, `quotes-${year}-${month}.csv`);
			} catch (error) {
				toastr.error(t("ErrorDownloading"));
			} finally {
				setIsDownloading(false);
				toggleCsvModal();
			}
		}
	};

	const exportUsers = async (id) => {
		setIsDownloading(true);
		try {
			const response = await HOTELS_CLIENT.buildCSVForUsers(id);
			downloadBlob(response.data, `users.csv`);
		} catch (error) {
			toastr.error(t("ErrorDownloading"));
		} finally {
			setIsDownloading(false);
			toggleExportUsersModal();
		}
	};

	useEffect(() => {
		fetchHotels();
	}, [sortOrder]);

	useEffect(() => {
		const debounceFn = setTimeout(() => {
			if (keyword) {
				fetchHotels();
				setSortOrder(defaultSort);
			}
		}, 250);

		return () => clearTimeout(debounceFn);
	}, [keyword]);

	return (
		<React.Fragment>
			<div className="page-content">
				<MetaTags>
					<title>{t("Clients")} | Quotelo</title>
				</MetaTags>
				<Container fluid>
					<Breadcrumbs title={t("ClientsManagement")} breadcrumbItem={t("Clients")} link="/clients" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row className="mb-4">
										<div className=" d-flex align-items-center justify-content-between">
											<div className="search-box ms-2 mb-2 d-inline-block">
												<div className="position-relative">
													<Input value={keyword || ""} onChange={handleKeywordChange} placeholder={t("SearchClients")} />
													<i className="bx bx-search-alt search-icon" />
												</div>
											</div>
											<div>
												<Button color="info" onClick={toggleExportUsersModal} className="mx-2" disabled={isDownloading}>
													<i className="bx bx-table pe-1" />
													<span>{t("ExportUsers")}</span>
												</Button>

												<Button color="info" onClick={toggleCsvModal} className="mx-2" disabled={isDownloading}>
													<i className="bx bx-table pe-1" />
													<span>{t("ExportQuotes")}</span>
												</Button>

												<Button
													color="info"
													onClick={() => {
														history.push("/clients/register-client");
													}}
													className="mx-2"
												>
													<i className="bx bx-plus pe-1" />
													<span>{t("AddClient")}</span>
												</Button>
											</div>
										</div>
									</Row>
									<Col xl="12">
										{loading ? (
											<LoadingSpinner />
										) : (
											<div className="table-responsive">
												<BootstrapTable
													keyField={"id"}
													data={hotels}
													columns={columns}
													bootstrap4
													defaultSorted={defaultSorted}
													rowEvents={rowEvents}
													hover
													rowStyle={{ cursor: "pointer" }}
													pagination={paginationFactory(paginationOptions)}
												/>
											</div>
										)}
									</Col>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
			<ExportCsvModal isOpen={csvModal} toggle={toggleCsvModal} action={exportCsv} loading={isDownloading} />
			{exportUsersModal ? <ExportUsersModal isOpen={exportUsersModal} toggle={toggleExportUsersModal} action={exportUsers} loading={isDownloading} /> : null}
		</React.Fragment>
	);
};

export default ClientsList;
