import React from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

// if type is not passed as a prop it defaults to "text"
const TextInput = (props) => {
	const { type, name, label, value, placeholder, htmlId, onChange, validation, invalid, isRequired, ...inputProps } = props;

	return (
		<FormGroup floating>
			<Input
				id={htmlId}
				name={name}
				placeholder={placeholder || " "}
				type={type || "text"}
				value={value}
				onChange={onChange}
				onWheel={type === "number" ? (e) => e.target.blur() : undefined}
				className={invalid && "is-invalid"}
				{...inputProps}
			/>
			<Label for={htmlId} className={`${invalid ? "text-danger" : "text-info"} opacity-100`} style={{ fontSize: 11 }}>
				{label}
				{isRequired && "*"}
			</Label>
			<FormFeedback type="invalid">{invalid ? validation.errors?.[name] : ""}</FormFeedback>
		</FormGroup>
	);
};

export default TextInput;
