import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

// users
import authService from "../../api-authorization/AuthorizeService";
import { ApplicationPaths } from "../../api-authorization/ApiAuthorizationConstants";

const ProfileMenu = (props) => {
	// Declare a new state variable, which we'll call "menu"
	const [menu, setMenu] = useState(false);
	const [user, setUser] = useState({});
	const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };

	useEffect(() => {
		const fetch = async () => {
			setUser(await authService.getUser());
		};
		fetch();
	}, [props.success]);

	return (
		<React.Fragment>
			<Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
				<DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
					<span className="d-none d-xl-inline-block ms-2 me-1">{user.name}</span>
					<i className="bx bx-chevron-down d-none d-xl-inline-block" />
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu-end">
					<DropdownItem tag="a" href={ApplicationPaths.Profile}>
						{" "}
						<i className="bx bx-user font-size-16 align-middle me-1" />
						{props.t("Profile")}{" "}
					</DropdownItem>
					<div className="dropdown-divider" />
					<Link to={logoutPath} className="dropdown-item">
						<i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
						<span>{props.t("Logout")}</span>
					</Link>
				</DropdownMenu>
			</Dropdown>
		</React.Fragment>
	);
};

ProfileMenu.propTypes = {
	success: PropTypes.any,
	t: PropTypes.any,
};

const mapStatetoProps = (state) => {
	//const {error, success} = state.Profile
	return {}; //{error, success}
};

export default withRouter(connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)));
