import React, { useRef, useState } from "react";
import BaseModal from "../../../components/Modals/BaseModal";
import { Button, ModalBody } from "reactstrap";
import ModalTitle from "../../../components/Modals/ModalTitle";
import moment from "moment";
import BaseModalFooter from "../../../components/Modals/BaseModalFooter";
import { useTranslation } from "react-i18next";

function ExportCsvModal({ isOpen, toggle, action, loading }) {
	const { t } = useTranslation();
	const [monthYear, setMonthYear] = useState(moment().format("YYYY-MM"));
	return (
		<BaseModal isOpen={isOpen} toggle={toggle} loading={loading}>
			<ModalBody>
				<ModalTitle title={t("ExportQuotes")} />
				<div className="d-flex justify-content-center align-items-center">
					<input type="month" value={monthYear} onChange={(e) => setMonthYear(e.target.value)} />
				</div>
			</ModalBody>
			<BaseModalFooter toggle={toggle}>
				<Button color="info" onClick={() => action(monthYear)} disabled={!monthYear}>
					{t("Confirm")}
				</Button>
			</BaseModalFooter>
		</BaseModal>
	);
}

export default ExportCsvModal;
