import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

const Checkbox = ({ label, name, checked, htmlId, onChange, disabled, value }) => {
	return (
		<FormGroup check inline>
			<Input type="checkbox" id={htmlId} name={name} value={value} defaultChecked={checked} onChange={(e) => onChange(e)} disabled={disabled} />
			<Label for={htmlId} check>
				{label}
			</Label>
		</FormGroup>
	);
};

export default Checkbox;
