import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import { Button, Row, CardBody, Card, Container } from "reactstrap";
import { COUNTRIES_CLIENT } from "../../../api/api_helper";
import { v4 as uuid } from "uuid";
import LoadingSpinner from "../../../components/UI/LoadingSpinner";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import AddCountryModal from "./AddCountryModal";
import useModal from "../../../hooks/useModal";
import BootstrapTable from "react-bootstrap-table-next";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import toastr from "toastr";

const Countries = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [countries, setCountries] = useState([]);
	const { open: addCountryModal, toggle: toggleAddCountryModal } = useModal();
	const { open: deleteModal, toggle: toggleDeleteModal } = useModal();
	const history = useHistory();
	const [selectedCountryId, setSelectedCountryId] = useState(null);
	const [deleting, setDeleting] = useState(false);

	async function getCountries() {
		setLoading(true);
		try {
			const countriesResponse = await COUNTRIES_CLIENT.getAll(true);
			setCountries(countriesResponse.map((country) => ({ ...country, uuid: uuid() })));
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	}

	async function deleteCountry() {
		setDeleting(true);
		try {
			if (selectedCountryId) await COUNTRIES_CLIENT.delete(selectedCountryId);
			setSelectedCountryId(null);
			toggleDeleteModal();
			await getCountries();
		} catch (error) {
			toastr.error(error);
		} finally {
			setDeleting(false);
		}
	}

	const columns = [
		{
			text: t("CountryName"),
			dataField: "name",
		},
		{
			text: t("Code"),
			dataField: "code",
		},
		{
			text: t("Currency"),
			dataField: "currencyName",
		},
		{
			text: "",
			dataField: "action",
			isDummyField: true,
			events: {
				onClick: (e) => {
					e.stopPropagation();
				},
			},
			formatter: (cell, row) => (
				<Button
					size="sm"
					color="danger"
					outline
					onClick={(e) => {
						e.stopPropagation();
						setSelectedCountryId(row.id);
						toggleDeleteModal();
					}}
				>
					<i className="bx bx-trash"></i>
				</Button>
			),
		},
	];

	const defaultSorted = [
		{
			dataField: "name",
			order: "desc",
		},
	];

	useEffect(() => {
		getCountries();
	}, []);

	return (
		<div className="page-content">
			<MetaTags>
				<title>{t("PlatformAndCountry")}</title>
			</MetaTags>

			<Breadcrumbs title={t("Settings")} breadcrumbItem={t("PlatformAndCountry")} link="/settings" />

			<Container fluid>
				<Card>
					<CardBody>
						{loading ? (
							<LoadingSpinner />
						) : (
							<>
								<Row className="mb-4">
									<div className="d-flex justify-content-end">
										<Button color="info" onClick={toggleAddCountryModal}>
											<i className="bx bx-plus me-1" />
											<span>{t("AddCountry")}</span>
										</Button>
									</div>
								</Row>

								<BootstrapTable
									keyField={"id"}
									data={countries}
									columns={columns}
									bootstrap4
									defaultSorted={defaultSorted}
									rowEvents={{
										onClick: (e, row) => {
											history.push(`/countries/${row.id}`);
										},
									}}
									hover
									rowStyle={{
										cursor: "pointer",
									}}
								/>
							</>
						)}
					</CardBody>
				</Card>

				{addCountryModal ? <AddCountryModal open={addCountryModal} toggle={toggleAddCountryModal} /> : null}

				<ConfirmationModal
					title={t("ConfirmDeleteCountry")}
					description={t("ConfirmDeleteCountryDescription")}
					isOpen={deleteModal}
					toggle={toggleDeleteModal}
					action={deleteCountry}
					loading={deleting}
					color="danger"
				/>
			</Container>
		</div>
	);
};

export default Countries;
