import axios from "axios";
import authService from "../components/api-authorization/AuthorizeService";
import { IdentityConfig } from "../components/api-authorization/ApiAuthorizationConstants";
import { UsersClient } from "./identity-api.service";
import { ResourcesClient, HotelsClient, CountryTaxesClient, AccountClient, DocumentsClient, CountryClient, CountriesClient } from "./hotel-api.service";
import toastr from "toastr";
import { QuotesClient } from "./quotation-api.service";

// API base URL
const HOTELS_API_URL = process.env.REACT_APP_HOTELS_API_URL;
const DOCUMENTS_API_URL = process.env.REACT_APP_DOCUMENTS_API_URL;
const IDENTITY_API_URL = process.env.REACT_APP_IDENTITY_SERVER_URL;
const QUOTES_API_URL = process.env.REACT_APP_QUOTES_API_URL;

const axiosHotelsApi = axios.create({
	baseURL: HOTELS_API_URL,
});
const axiosDocumentsApi = axios.create({
	baseURL: DOCUMENTS_API_URL,
});
const axiosIdentityApi = axios.create({
	baseURL: IDENTITY_API_URL,
});
const axiosQuotesApi = axios.create({
	baseURL: QUOTES_API_URL,
});

axiosDocumentsApi.interceptors.request.use((config) => {
	const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
	config.params = { culture: currentLanguage, ...config.params };
	return config;
});
axiosHotelsApi.interceptors.request.use((config) => {
	const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
	config.params = { culture: currentLanguage, ...config.params };
	return config;
});
axiosIdentityApi.interceptors.request.use((config) => {
	const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
	config.params = { culture: currentLanguage, ...config.params };
	return config;
});

export const setupAxiosApi = async () => {
	if (process.env.REACT_APP_ENV === "development") {
		await axios({
			method: "post",
			url: `${IdentityConfig.authority}/connect/token`,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			data: "client_id=m2m.ACG.Quotelo.Quotation&client_secret=DDC1ACA0-A05C-4607-AE32-1773A8B8BCF4&grant_type=password&scope=profile email roles quotelo&username=superadmin@quotelo&password=Quotelo@2022",
		})
			.then(function (response) {
				localStorage.setItem("accessTokenDev", response.data.access_token);
				axiosHotelsApi.defaults.headers.common["Authorization"] = `Bearer ${response.data.access_token}`;
				axiosDocumentsApi.defaults.headers.common["Authorization"] = `Bearer ${response.data.access_token}`;
				axiosIdentityApi.defaults.headers.common["Authorization"] = `Bearer ${response.data.access_token}`;
				axiosQuotesApi.defaults.headers.common["Authorization"] = `Bearer ${response.data.access_token}`;
			})
			.catch(function (error) {});
	} else {
		const token = await authService.getAccessToken();
		axiosHotelsApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		axiosDocumentsApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		axiosIdentityApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		axiosQuotesApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	}

	function showError(err) {
		let msg = "";
		if (err.errors) {
			msg = "<ul>";
			for (const prop in err.errors) {
				if (Object.prototype.hasOwnProperty.call(err.errors, prop)) {
					// msg += `<li>${prop}: ${err.errors[prop].join(", ")}</li>`;
					msg += `<li>${err.errors[prop].join(", ")}</li>`;
				}
			}
			msg += "</ul>";
		} else if (err.detail) {
			msg = err.detail;
			try {
				const errorObj = JSON.parse(msg);
				if (errorObj.hasOwnProperty("error")) msg = errorObj.error;
			} catch (er) {}
		}
		toastr.error(msg, err.title);
	}

	function onAxiosResponseError(error) {
		if (error.response?.status === 401 /*Unauthorized*/) {
			if (!process.env.REACT_APP_ENV === "development") {
				history.pushState({}, "", "/authentication/login");
				return Promise.resolve();
			}
		} else {
			if (
				error.request.responseType === "blob" &&
				error.response.data instanceof Blob &&
				error.response.data.type &&
				error.response.data.type.toLowerCase().indexOf("json") != -1
			) {
				error.response.data.text().then((err) => {
					showError(JSON.parse(err));
				});
			} else {
				showError(error.response?.data || "");
			}
		}
		return Promise.reject(error);
	}

	axiosHotelsApi.interceptors.response.use((res) => res, onAxiosResponseError);
	axiosDocumentsApi.interceptors.response.use((res) => res, onAxiosResponseError);
	axiosIdentityApi.interceptors.response.use((res) => res, onAxiosResponseError);
};

// setupAxiosApi();

export const RESOURCES_CLIENT = new ResourcesClient(HOTELS_API_URL, axiosHotelsApi);
export const USERS_CLIENT = new UsersClient(IDENTITY_API_URL, axiosIdentityApi);
export const HOTELS_CLIENT = new HotelsClient(HOTELS_API_URL, axiosHotelsApi);
export const COUNTRIES_CLIENT = new CountriesClient(HOTELS_API_URL, axiosHotelsApi);
export const ACCOUNTS_CLIENT = new AccountClient(HOTELS_API_URL, axiosHotelsApi);
export const QUOTES_CLIENT = new QuotesClient(QUOTES_API_URL, axiosQuotesApi);
export const DOCUMENTS_CLIENT = new DocumentsClient(DOCUMENTS_API_URL, axiosDocumentsApi);
