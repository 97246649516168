import PropTypes from "prop-types";
import React from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logo from "../../assets/images/Quotelo_logo_white.png";
import logoLightSvg from "../../assets/images/Quotelo_logo_white.png";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu, changeSidebarType } from "../../store/actions";

const Header = (props) => {
	function tToggle() {
		var body = document.body;
		if (window.screen.width <= 998) {
			body.classList.toggle("sidebar-enable");
		} else {
			body.classList.toggle("vertical-collpsed");
			body.classList.toggle("sidebar-enable");
		}
	}

	return (
		<React.Fragment>
			<header id="page-topbar">
				<div className="navbar-header">
					<div className="d-flex">
						<div className="navbar-brand-box d-lg-none d-md-block">
							<Link to="/" className="logo logo-dark">
								<span className="logo-sm">
									<img src={logo} alt="" height="22" />
								</span>
							</Link>

							<Link to="/" className="logo logo-light">
								<span className="logo-sm">
									<img src={logoLightSvg} alt="" height="22" />
								</span>
							</Link>
						</div>

						<button
							type="button"
							onClick={() => {
								tToggle();
							}}
							className="btn btn-sm px-3 font-size-24 header-item "
							id="vertical-menu-btn"
						>
							<i className="bx bx-menu" />
						</button>
					</div>
					<div className="d-flex">
						<ProfileMenu />
					</div>
				</div>
			</header>
		</React.Fragment>
	);
};

Header.propTypes = {
	changeSidebarType: PropTypes.func,
	leftMenu: PropTypes.any,
	leftSideBarType: PropTypes.any,
	showRightSidebar: PropTypes.any,
	showRightSidebarAction: PropTypes.func,
	t: PropTypes.any,
	toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
	const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
	return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
	showRightSidebarAction,
	toggleLeftmenu,
	changeSidebarType,
})(withTranslation()(Header));
