import React from "react";
import { Button, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import ModalTitle from "./ModalTitle";
import BaseModal from "./BaseModal";
import ModalText from "./ModalText";
import BaseModalFooter from "./BaseModalFooter";

const ConfirmationModal = ({ isOpen, toggle, title, description, action, customButton, loading, color, ...rest }) => {
	const { t } = useTranslation();

	return (
		<BaseModal isOpen={isOpen} toggle={toggle} loading={loading} {...rest}>
			<ModalBody className="text-center">
				<ModalTitle title={title} color={color} />
				<ModalText>{description}</ModalText>
			</ModalBody>
			<BaseModalFooter toggle={toggle}>
				{customButton ? (
					customButton
				) : (
					<Button color={color || "info"} onClick={action}>
						{t("Confirm")}
					</Button>
				)}
			</BaseModalFooter>
		</BaseModal>
	);
};

export default ConfirmationModal;
