export const countryLocales = [
	{
		country: "AD",
		en_name: "Andorra",
		native_name: "Andorra",
		locales: "ca",
	},
	{
		country: "AE",
		en_name: "United Arab Emirates",
		native_name: "دولة الإمارات العربيّة المتّحدة",
		locales: "ar",
	},
	{
		country: "AF",
		en_name: "Afghanistan",
		native_name: "د افغانستان اسلامي دولتدولت اسلامی افغانستان, جمهوری اسلامی افغانستان",
		locales: "fa, ps",
	},
	{
		country: "AG",
		en_name: "Antigua and Barbuda",
		native_name: "Antigua and Barbuda",
		locales: "en",
	},
	{
		country: "AI",
		en_name: "Anguilla",
		native_name: "Anguilla",
		locales: "en",
	},
	{
		country: "AL",
		en_name: "Albania",
		native_name: "Shqipëria",
		locales: "sq",
	},
	{
		country: "AM",
		en_name: "Armenia",
		native_name: "Հայաստան",
		locales: "hy",
	},
	{
		country: "AO",
		en_name: "Angola",
		native_name: "Angola",
		locales: "pt",
	},
	{
		country: "AQ",
		en_name: "Antarctica",
		native_name: "Antarctica, Antártico, Antarctique, Антарктике",
		locales: "en, es, fr, ru[1]",
	},
	{
		country: "AR",
		en_name: "Argentina",
		native_name: "Argentina",
		locales: "es",
	},
	{
		country: "AS",
		en_name: "American Samoa",
		native_name: "American Samoa",
		locales: "en, sm",
	},
	{
		country: "AT",
		en_name: "Austria",
		native_name: "Österreich",
		locales: "de",
	},
	{
		country: "AU",
		en_name: "Australia",
		native_name: "Australia",
		locales: "en",
	},
	{
		country: "AW",
		en_name: "Aruba",
		native_name: "Aruba",
		locales: "nl, pap",
	},
	{
		country: "AX",
		en_name: "Aland Islands",
		native_name: "Åland",
		locales: "sv",
	},
	{
		country: "AZ",
		en_name: "Azerbaijan",
		native_name: "Azərbaycan",
		locales: "az",
	},
	{
		country: "BA",
		en_name: "Bosnia and Herzegovina",
		native_name: "Bosna i Hercegovina",
		locales: "bs, hr, sr",
	},
	{
		country: "BB",
		en_name: "Barbados",
		native_name: "Barbados",
		locales: "en",
	},
	{
		country: "BD",
		en_name: "Bangladesh",
		native_name: "গণপ্রজাতন্ত্রী বাংলাদেশ",
		locales: "bn",
	},
	{
		country: "BE",
		en_name: "Belgium",
		native_name: "België, Belgique, Belgien",
		locales: "nl, fr, de",
	},
	{
		country: "BF",
		en_name: "Burkina Faso",
		native_name: "Burkina Faso",
		locales: "fr",
	},
	{
		country: "BG",
		en_name: "Bulgaria",
		native_name: "България",
		locales: "bg",
	},
	{
		country: "BH",
		en_name: "Bahrain",
		native_name: "البحرين",
		locales: "ar",
	},
	{
		country: "BI",
		en_name: "Burundi",
		native_name: "Burundi",
		locales: "fr",
	},
	{
		country: "BJ",
		en_name: "Benin",
		native_name: "Bénin",
		locales: "fr",
	},
	{
		country: "BL",
		en_name: "Saint-Barthélemy",
		native_name: "Saint-Barthélemy",
		locales: "fr",
	},
	{
		country: "BM",
		en_name: "Bermuda",
		native_name: "Bermuda",
		locales: "en",
	},
	{
		country: "BN",
		en_name: "Brunei Darussalam",
		native_name: "Brunei Darussalam",
		locales: "ms",
	},
	{
		country: "BO",
		en_name: "Bolivia",
		native_name: "Bolivia, Bulibiya, Volívia, Wuliwya",
		locales: "es, qu, gn, ay",
	},
	{
		country: "BQ",
		en_name: "Caribbean Netherlands",
		native_name: "Caribisch Nederland",
		locales: "nl",
	},
	{
		country: "BR",
		en_name: "Brazil",
		native_name: "Brasil",
		locales: "pt",
	},
	{
		country: "BS",
		en_name: "Bahamas",
		native_name: "Bahamas",
		locales: "en",
	},
	{
		country: "BT",
		en_name: "Bhutan",
		native_name: "འབྲུག་ཡུལ",
		locales: "dz",
	},
	{
		country: "BV",
		en_name: "Bouvet Island",
		native_name: "Bouvetøya",
		locales: "no",
	},
	{
		country: "BW",
		en_name: "Botswana",
		native_name: "Botswana",
		locales: "en, tn",
	},
	{
		country: "BY",
		en_name: "Belarus",
		native_name: "Беларусь",
		locales: "be, ru",
	},
	{
		country: "BZ",
		en_name: "Belize",
		native_name: "Belize",
		locales: "en",
	},
	{
		country: "CA",
		en_name: "Canada",
		native_name: "Canada",
		locales: "en, fr",
	},
	{
		country: "CC",
		en_name: "Cocos (Keeling) Islands",
		native_name: "Cocos (Keeling) Islands",
		locales: "en",
	},
	{
		country: "CD",
		en_name: "Democratic Republic of the Congo (Congo-Kinshasa, former Zaire)",
		native_name: "République Démocratique du Congo",
		locales: "fr",
	},
	{
		country: "CF",
		en_name: "Centrafrican Republic",
		native_name: "République centrafricaine, Ködörösêse tî Bêafrîka",
		locales: "fr, sg",
	},
	{
		country: "CG",
		en_name: "Republic of the Congo (Congo-Brazzaville)",
		native_name: "République du Congo",
		locales: "fr",
	},
	{
		country: "CH",
		en_name: "Switzerland",
		native_name: "Schweiz, Suisse, Svizzera, Svizra",
		locales: "de, fr, it, rm",
	},
	{
		country: "CI",
		en_name: "Côte d'Ivoire",
		native_name: "Côte d'Ivoire",
		locales: "fr",
	},
	{
		country: "CK",
		en_name: "Cook Islands",
		native_name: "Cook Islands, Kūki ʻĀirani",
		locales: "en, rar",
	},
	{
		country: "CL",
		en_name: "Chile",
		native_name: "Chile",
		locales: "es",
	},
	{
		country: "CM",
		en_name: "Cameroon",
		native_name: "Cameroun, Cameroon",
		locales: "fr, en",
	},
	{
		country: "CN",
		en_name: "China",
		native_name: "中国",
		locales: "zh-hans",
	},
	{
		country: "CO",
		en_name: "Colombia",
		native_name: "Colombia",
		locales: "es",
	},
	{
		country: "CR",
		en_name: "Costa Rica",
		native_name: "Costa Rica",
		locales: "es",
	},
	{
		country: "CU",
		en_name: "Cuba",
		native_name: "Cuba",
		locales: "es",
	},
	{
		country: "CV",
		en_name: "Cabo Verde",
		native_name: "Cabo Verde",
		locales: "pt",
	},
	{
		country: "CW",
		en_name: "Curaçao",
		native_name: "Curaçao",
		locales: "nl, en",
	},
	{
		country: "CX",
		en_name: "Christmas Island",
		native_name: "Christmas Island",
		locales: "en",
	},
	{
		country: "CY",
		en_name: "Cyprus",
		native_name: "Κύπρος, Kibris",
		locales: "el, tr",
	},
	{
		country: "CZ",
		en_name: "Czech Republic",
		native_name: "Česká republika",
		locales: "cs",
	},
	{
		country: "DE",
		en_name: "Germany",
		native_name: "Deutschland",
		locales: "de",
	},
	{
		country: "DJ",
		en_name: "Djibouti",
		native_name: "Djibouti, جيبوتي, Jabuuti, Gabuutih",
		locales: "fr, ar, so, aa",
	},
	{
		country: "DK",
		en_name: "Denmark",
		native_name: "Danmark",
		locales: "da",
	},
	{
		country: "DM",
		en_name: "Dominica",
		native_name: "Dominica",
		locales: "en",
	},
	{
		country: "DO",
		en_name: "Dominican Republic",
		native_name: "República Dominicana",
		locales: "es",
	},
	{
		country: "DZ",
		en_name: "Algeria",
		native_name: "الجزائر",
		locales: "ar",
	},
	{
		country: "EC",
		en_name: "Ecuador",
		native_name: "Ecuador",
		locales: "es",
	},
	{
		country: "EE",
		en_name: "Estonia",
		native_name: "Eesti",
		locales: "et",
	},
	{
		country: "EG",
		en_name: "Egypt",
		native_name: "مصر",
		locales: "ar",
	},
	{
		country: "EH",
		en_name: "Western Sahara",
		native_name: "Sahara Occidental",
		locales: "ar, es, fr",
	},
	{
		country: "ER",
		en_name: "Eritrea",
		native_name: "ኤርትራ, إرتريا, Eritrea",
		locales: "ti, ar, en",
	},
	{
		country: "ES",
		en_name: "Spain",
		native_name: "España",
		locales: "ast, ca, es, eu, gl",
	},
	{
		country: "ET",
		en_name: "Ethiopia",
		native_name: "ኢትዮጵያ, Itoophiyaa",
		locales: "am, om",
	},
	{
		country: "FI",
		en_name: "Finland",
		native_name: "Suomi",
		locales: "fi, sv, se",
	},
	{
		country: "FJ",
		en_name: "Fiji",
		native_name: "Fiji",
		locales: "en",
	},
	{
		country: "FK",
		en_name: "Falkland Islands",
		native_name: "Falkland Islands",
		locales: "en",
	},
	{
		country: "FM",
		en_name: "Micronesia (Federated States of)",
		native_name: "Micronesia",
		locales: "en",
	},
	{
		country: "FO",
		en_name: "Faroe Islands",
		native_name: "Føroyar, Færøerne",
		locales: "fo, da",
	},
	{
		country: "FR",
		en_name: "France",
		native_name: "France",
		locales: "fr",
	},
	{
		country: "GA",
		en_name: "Gabon",
		native_name: "Gabon",
		locales: "fr",
	},
	{
		country: "GB",
		en_name: "United Kingdom",
		native_name: "United Kingdom",
		locales: "en, ga, cy, gd, kw",
	},
	{
		country: "GD",
		en_name: "Grenada",
		native_name: "Grenada",
		locales: "en",
	},
	{
		country: "GE",
		en_name: "Georgia",
		native_name: "საქართველო",
		locales: "ka",
	},
	{
		country: "GF",
		en_name: "French Guiana",
		native_name: "Guyane française",
		locales: "fr",
	},
	{
		country: "GG",
		en_name: "Guernsey",
		native_name: "Guernsey",
		locales: "en",
	},
	{
		country: "GH",
		en_name: "Ghana",
		native_name: "Ghana",
		locales: "en",
	},
	{
		country: "GI",
		en_name: "Gibraltar",
		native_name: "Gibraltar",
		locales: "en",
	},
	{
		country: "GL",
		en_name: "Greenland",
		native_name: "Kalaallit Nunaat, Grønland",
		locales: "kl, da",
	},
	{
		country: "GM",
		en_name: "The Gambia",
		native_name: "The Gambia",
		locales: "en",
	},
	{
		country: "GN",
		en_name: "Guinea",
		native_name: "Guinée",
		locales: "fr",
	},
	{
		country: "GP",
		en_name: "Guadeloupe",
		native_name: "Guadeloupe",
		locales: "fr",
	},
	{
		country: "GQ",
		en_name: "Equatorial Guinea",
		native_name: "Guiena ecuatorial, Guinée équatoriale, Guiné Equatorial",
		locales: "es, fr, pt",
	},
	{
		country: "GR",
		en_name: "Greece",
		native_name: "Ελλάδα",
		locales: "el",
	},
	{
		country: "GS",
		en_name: "South Georgia and the South Sandwich Islands",
		native_name: "South Georgia and the South Sandwich Islands",
		locales: "en",
	},
	{
		country: "GT",
		en_name: "Guatemala",
		native_name: "Guatemala",
		locales: "es",
	},
	{
		country: "GU",
		en_name: "Guam",
		native_name: "Guam, Guåhån",
		locales: "en, ch",
	},
	{
		country: "GW",
		en_name: "Guinea Bissau",
		native_name: "Guiné-Bissau",
		locales: "pt",
	},
	{
		country: "GY",
		en_name: "Guyana",
		native_name: "Guyana",
		locales: "en",
	},
	{
		country: "HK",
		en_name: "Hong Kong (SAR of China)",
		native_name: "香港, Hong Kong",
		locales: "zh-hant, en",
	},
	{
		country: "HM",
		en_name: "Heard Island and McDonald Islands",
		native_name: "Heard Island and McDonald Islands",
		locales: "en",
	},
	{
		country: "HN",
		en_name: "Honduras",
		native_name: "Honduras",
		locales: "es",
	},
	{
		country: "HR",
		en_name: "Croatia",
		native_name: "Hrvatska",
		locales: "hr",
	},
	{
		country: "HT",
		en_name: "Haiti",
		native_name: "Haïti, Ayiti",
		locales: "fr, ht",
	},
	{
		country: "HU",
		en_name: "Hungary",
		native_name: "Magyarország",
		locales: "hu",
	},
	{
		country: "ID",
		en_name: "Indonesia",
		native_name: "Indonesia",
		locales: "id",
	},
	{
		country: "IE",
		en_name: "Ireland",
		native_name: "Ireland, Éire",
		locales: "en, ga",
	},
	{
		country: "IL",
		en_name: "Israel",
		native_name: "ישראל",
		locales: "he",
	},
	{
		country: "IM",
		en_name: "Isle of Man",
		native_name: "Isle of Man",
		locales: "en",
	},
	{
		country: "IN",
		en_name: "India",
		native_name: "भारत, India",
		locales: "hi, en",
	},
	{
		country: "IO",
		en_name: "British Indian Ocean Territory",
		native_name: "British Indian Ocean Territory",
		locales: "en",
	},
	{
		country: "IQ",
		en_name: "Iraq",
		native_name: "العراق, Iraq",
		locales: "ar, ku",
	},
	{
		country: "IR",
		en_name: "Iran",
		native_name: "ایران",
		locales: "fa",
	},
	{
		country: "IS",
		en_name: "Iceland",
		native_name: "Ísland",
		locales: "is",
	},
	{
		country: "IT",
		en_name: "Italy",
		native_name: "Italia",
		locales: "it, de, fr",
	},
	{
		country: "JE",
		en_name: "Jersey",
		native_name: "Jersey",
		locales: "en",
	},
	{
		country: "JM",
		en_name: "Jamaica",
		native_name: "Jamaica",
		locales: "en",
	},
	{
		country: "JO",
		en_name: "Jordan",
		native_name: "الأُرْدُن",
		locales: "ar",
	},
	{
		country: "JP",
		en_name: "Japan",
		native_name: "日本",
		locales: "ja",
	},
	{
		country: "KE",
		en_name: "Kenya",
		native_name: "Kenya",
		locales: "sw, en",
	},
	{
		country: "KG",
		en_name: "Kyrgyzstan",
		native_name: "Кыргызстан, Киргизия",
		locales: "ky, ru",
	},
	{
		country: "KH",
		en_name: "Cambodia",
		native_name: "កម្ពុជា",
		locales: "km",
	},
	{
		country: "KI",
		en_name: "Kiribati",
		native_name: "Kiribati",
		locales: "en",
	},
	{
		country: "KM",
		en_name: "Comores",
		native_name: "ﺍﻟﻘﻤﺮي, Comores, Komori",
		locales: "ar, fr, sw",
	},
	{
		country: "KN",
		en_name: "Saint Kitts and Nevis",
		native_name: "Saint Kitts and Nevis",
		locales: "en",
	},
	{
		country: "KP",
		en_name: "North Korea",
		native_name: "북조선",
		locales: "ko",
	},
	{
		country: "KR",
		en_name: "South Korea",
		native_name: "대한민국",
		locales: "ko, en",
	},
	{
		country: "KW",
		en_name: "Kuwait",
		native_name: "الكويت",
		locales: "ar",
	},
	{
		country: "KY",
		en_name: "Cayman Islands",
		native_name: "Cayman Islands",
		locales: "en",
	},
	{
		country: "KZ",
		en_name: "Kazakhstan",
		native_name: "Қазақстан, Казахстан",
		locales: "kk, ru",
	},
	{
		country: "LA",
		en_name: "Laos",
		native_name: "ປະຊາຊົນລາວ",
		locales: "lo",
	},
	{
		country: "LB",
		en_name: "Lebanon",
		native_name: "لبنان, Liban",
		locales: "ar, fr",
	},
	{
		country: "LC",
		en_name: "Saint Lucia",
		native_name: "Saint Lucia",
		locales: "en",
	},
	{
		country: "LI",
		en_name: "Liechtenstein",
		native_name: "Liechtenstein",
		locales: "de",
	},
	{
		country: "LK",
		en_name: "Sri Lanka",
		native_name: "ශ්‍රී ලංකා, இலங்கை",
		locales: "si, ta",
	},
	{
		country: "LR",
		en_name: "Liberia",
		native_name: "Liberia",
		locales: "en",
	},
	{
		country: "LS",
		en_name: "Lesotho",
		native_name: "Lesotho",
		locales: "en, st",
	},
	{
		country: "LT",
		en_name: "Lithuania",
		native_name: "Lietuva",
		locales: "lt",
	},
	{
		country: "LU",
		en_name: "Luxembourg",
		native_name: "Lëtzebuerg, Luxembourg, Luxemburg",
		locales: "lb, fr, de",
	},
	{
		country: "LV",
		en_name: "Latvia",
		native_name: "Latvija",
		locales: "lv",
	},
	{
		country: "LY",
		en_name: "Libya",
		native_name: "ليبيا",
		locales: "ar",
	},
	{
		country: "MA",
		en_name: "Morocco",
		native_name: "Maroc, ⵍⵎⵖⵔⵉⴱ, المغرب",
		locales: "fr, zgh, ar",
	},
	{
		country: "MC",
		en_name: "Monaco",
		native_name: "Monaco",
		locales: "fr",
	},
	{
		country: "MD",
		en_name: "Moldova",
		native_name: "Moldova, Молдавия",
		locales: "ro, ru, uk",
	},
	{
		country: "ME",
		en_name: "Montenegro",
		native_name: "Crna Gora, Црна Гора",
		locales: "srp, sr, hr, bs, sq",
	},
	{
		country: "MF",
		en_name: "Saint Martin (French part)",
		native_name: "Saint-Martin",
		locales: "fr",
	},
	{
		country: "MG",
		en_name: "Madagascar",
		native_name: "Madagasikara, Madagascar",
		locales: "mg, fr",
	},
	{
		country: "MH",
		en_name: "Marshall Islands",
		native_name: "Marshall Islands",
		locales: "en, mh",
	},
	{
		country: "MK",
		en_name: "Macedonia (Former Yugoslav Republic of)",
		native_name: "Македонија",
		locales: "mk",
	},
	{
		country: "ML",
		en_name: "Mali",
		native_name: "Mali",
		locales: "fr",
	},
	{
		country: "MM",
		en_name: "Myanmar",
		native_name: "မြန်မာ",
		locales: "my",
	},
	{
		country: "MN",
		en_name: "Mongolia",
		native_name: "Монгол Улс",
		locales: "mn",
	},
	{
		country: "MO",
		en_name: "Macao (SAR of China)",
		native_name: "澳門, Macau",
		locales: "zh-hant, pt",
	},
	{
		country: "MP",
		en_name: "Northern Mariana Islands",
		native_name: "Northern Mariana Islands",
		locales: "en, ch",
	},
	{
		country: "MQ",
		en_name: "Martinique",
		native_name: "Martinique",
		locales: "fr",
	},
	{
		country: "MR",
		en_name: "Mauritania",
		native_name: "موريتانيا, Mauritanie",
		locales: "ar, fr",
	},
	{
		country: "MS",
		en_name: "Montserrat",
		native_name: "Montserrat",
		locales: "en",
	},
	{
		country: "MT",
		en_name: "Malta",
		native_name: "Malta",
		locales: "mt, en",
	},
	{
		country: "MU",
		en_name: "Mauritius",
		native_name: "Maurice, Mauritius",
		locales: "mfe, fr, en",
	},
	{
		country: "MV",
		en_name: "Maldives",
		native_name: "",
		locales: "dv",
	},
	{
		country: "MW",
		en_name: "Malawi",
		native_name: "Malawi",
		locales: "en, ny",
	},
	{
		country: "MX",
		en_name: "Mexico",
		native_name: "México",
		locales: "es",
	},
	{
		country: "MY",
		en_name: "Malaysia",
		native_name: "",
		locales: "ms",
	},
	{
		country: "MZ",
		en_name: "Mozambique",
		native_name: "Mozambique",
		locales: "pt",
	},
	{
		country: "NA",
		en_name: "Namibia",
		native_name: "Namibia",
		locales: "en, sf, de",
	},
	{
		country: "NC",
		en_name: "New Caledonia",
		native_name: "Nouvelle-Calédonie",
		locales: "fr",
	},
	{
		country: "NE",
		en_name: "Niger",
		native_name: "Niger",
		locales: "fr",
	},
	{
		country: "NF",
		en_name: "Norfolk Island",
		native_name: "Norfolk Island",
		locales: "en, pih",
	},
	{
		country: "NG",
		en_name: "Nigeria",
		native_name: "Nigeria",
		locales: "en",
	},
	{
		country: "NI",
		en_name: "Nicaragua",
		native_name: "Nicaragua",
		locales: "es",
	},
	{
		country: "NL",
		en_name: "The Netherlands",
		native_name: "Nederland",
		locales: "nl",
	},
	{
		country: "NO",
		en_name: "Norway",
		native_name: "Norge, Noreg",
		locales: "nb, nn, no, se",
	},
	{
		country: "NP",
		en_name: "Nepal",
		native_name: "",
		locales: "ne",
	},
	{
		country: "NR",
		en_name: "Nauru",
		native_name: "Nauru",
		locales: "na, en",
	},
	{
		country: "NU",
		en_name: "Niue",
		native_name: "Niue",
		locales: "niu, en",
	},
	{
		country: "NZ",
		en_name: "New Zealand",
		native_name: "New Zealand",
		locales: "mi, en",
	},
	{
		country: "OM",
		en_name: "Oman",
		native_name: "سلطنة عُمان",
		locales: "ar",
	},
	{
		country: "PA",
		en_name: "Panama",
		native_name: "Panama",
		locales: "es",
	},
	{
		country: "PE",
		en_name: "Peru",
		native_name: "Perú",
		locales: "es",
	},
	{
		country: "PF",
		en_name: "French Polynesia",
		native_name: "Polynésie française",
		locales: "fr",
	},
	{
		country: "PG",
		en_name: "Papua New Guinea",
		native_name: "Papua New Guinea",
		locales: "en, tpi, ho",
	},
	{
		country: "PH",
		en_name: "Philippines",
		native_name: "Philippines",
		locales: "en, tl",
	},
	{
		country: "PK",
		en_name: "Pakistan",
		native_name: "پاکستان",
		locales: "en, ur",
	},
	{
		country: "PL",
		en_name: "Poland",
		native_name: "Polska",
		locales: "pl",
	},
	{
		country: "PM",
		en_name: "Saint Pierre and Miquelon",
		native_name: "Saint-Pierre-et-Miquelon",
		locales: "fr",
	},
	{
		country: "PN",
		en_name: "Pitcairn",
		native_name: "Pitcairn",
		locales: "en, pih",
	},
	{
		country: "PR",
		en_name: "Puerto Rico",
		native_name: "Puerto Rico",
		locales: "es, en",
	},
	{
		country: "PS",
		en_name: "Palestinian Territory",
		native_name: "Palestinian Territory",
		locales: "ar, he",
	},
	{
		country: "PT",
		en_name: "Portugal",
		native_name: "Portugal",
		locales: "pt",
	},
	{
		country: "PW",
		en_name: "Palau",
		native_name: "Palau",
		locales: "en, pau, ja, sov, tox",
	},
	{
		country: "PY",
		en_name: "Paraguay",
		native_name: "Paraguay",
		locales: "es, gn",
	},
	{
		country: "QA",
		en_name: "Qatar",
		native_name: "قطر",
		locales: "ar",
	},
	{
		country: "RE",
		en_name: "Reunion",
		native_name: "La Réunion",
		locales: "fr",
	},
	{
		country: "RO",
		en_name: "Romania",
		native_name: "România",
		locales: "ro",
	},
	{
		country: "RS",
		en_name: "Serbia",
		native_name: "Србија",
		locales: "sr, sr-Latn",
	},
	{
		country: "RU",
		en_name: "Russia",
		native_name: "Россия",
		locales: "ru",
	},
	{
		country: "RW",
		en_name: "Rwanda",
		native_name: "Rwanda",
		locales: "rw, fr, en",
	},
	{
		country: "SA",
		en_name: "Saudi Arabia",
		native_name: "السعودية",
		locales: "ar",
	},
	{
		country: "SB",
		en_name: "Solomon Islands",
		native_name: "Solomon Islands",
		locales: "en",
	},
	{
		country: "SC",
		en_name: "Seychelles",
		native_name: "Seychelles",
		locales: "fr, en, crs",
	},
	{
		country: "SD",
		en_name: "Sudan",
		native_name: "السودان",
		locales: "ar, en",
	},
	{
		country: "SE",
		en_name: "Sweden",
		native_name: "Sverige",
		locales: "sv",
	},
	{
		country: "SG",
		en_name: "Singapore",
		native_name: "Singapore",
		locales: "zh-hans, en, ms, ta",
	},
	{
		country: "SH",
		en_name: "Saint Helena",
		native_name: "Saint Helena",
		locales: "en",
	},
	{
		country: "SI",
		en_name: "Slovenia",
		native_name: "Slovenija",
		locales: "sl",
	},
	{
		country: "SJ",
		en_name: "Svalbard and Jan Mayen",
		native_name: "Svalbard and Jan Mayen",
		locales: "no",
	},
	{
		country: "SK",
		en_name: "Slovakia",
		native_name: "Slovensko",
		locales: "sk",
	},
	{
		country: "SL",
		en_name: "Sierra Leone",
		native_name: "Sierra Leone",
		locales: "en",
	},
	{
		country: "SM",
		en_name: "San Marino",
		native_name: "San Marino",
		locales: "it",
	},
	{
		country: "SN",
		en_name: "Sénégal",
		native_name: "Sénégal",
		locales: "fr",
	},
	{
		country: "SO",
		en_name: "Somalia",
		native_name: "Somalia, الصومال",
		locales: "so, ar",
	},
	{
		country: "SR",
		en_name: "Suriname",
		native_name: "Suriname",
		locales: "nl",
	},
	{
		country: "ST",
		en_name: "São Tomé and Príncipe",
		native_name: "São Tomé e Príncipe",
		locales: "pt",
	},
	{
		country: "SS",
		en_name: "South Sudan",
		native_name: "South Sudan",
		locales: "en",
	},
	{
		country: "SV",
		en_name: "El Salvador",
		native_name: "El Salvador",
		locales: "es",
	},
	{
		country: "SX",
		en_name: "Saint Martin (Dutch part)",
		native_name: "Sint Maarten",
		locales: "nl, en",
	},
	{
		country: "SY",
		en_name: "Syria",
		native_name: "سوريا, Sūriyya",
		locales: "ar",
	},
	{
		country: "SZ",
		en_name: "Swaziland",
		native_name: "Swaziland",
		locales: "en, ss",
	},
	{
		country: "TC",
		en_name: "Turks and Caicos Islands",
		native_name: "Turks and Caicos Islands",
		locales: "en",
	},
	{
		country: "TD",
		en_name: "Chad",
		native_name: "Tchad, تشاد",
		locales: "fr, ar",
	},
	{
		country: "TF",
		en_name: "French Southern and Antarctic Lands",
		native_name: "Terres australes et antarctiques françaises",
		locales: "fr",
	},
	{
		country: "TG",
		en_name: "Togo",
		native_name: "Togo",
		locales: "fr",
	},
	{
		country: "TH",
		en_name: "Thailand",
		native_name: "ประเทศไทย",
		locales: "th",
	},
	{
		country: "TJ",
		en_name: "Tajikistan",
		native_name: ",",
		locales: "tg, ru",
	},
	{
		country: "TK",
		en_name: "Tokelau",
		native_name: "Tokelau",
		locales: "tkl, en, sm",
	},
	{
		country: "TL",
		en_name: "Timor-Leste",
		native_name: "Timor-Leste, Timor Lorosa'e",
		locales: "pt, tet",
	},
	{
		country: "TM",
		en_name: "Turkmenistan",
		native_name: "Türkmenistan",
		locales: "tk",
	},
	{
		country: "TN",
		en_name: "Tunisia",
		native_name: "تونس, Tunisie",
		locales: "ar, fr",
	},
	{
		country: "TO",
		en_name: "Tonga",
		native_name: "Tonga",
		locales: "en",
	},
	{
		country: "TR",
		en_name: "Turkey",
		native_name: "Türkiye",
		locales: "tr",
	},
	{
		country: "TT",
		en_name: "Trinidad and Tobago",
		native_name: "Trinidad and Tobago",
		locales: "en",
	},
	{
		country: "TV",
		en_name: "Tuvalu",
		native_name: "Tuvalu",
		locales: "en",
	},
	{
		country: "TW",
		en_name: "Taiwan",
		native_name: "Taiwan",
		locales: "zh-hant",
	},
	{
		country: "TZ",
		en_name: "Tanzania",
		native_name: "Tanzania",
		locales: "sw, en",
	},
	{
		country: "UA",
		en_name: "Ukraine",
		native_name: "Україна",
		locales: "uk",
	},
	{
		country: "UG",
		en_name: "Uganda",
		native_name: "Uganda",
		locales: "en, sw",
	},
	{
		country: "UM",
		en_name: "United States Minor Outlying Islands",
		native_name: "United States Minor Outlying Islands",
		locales: "en",
	},
	{
		country: "US",
		en_name: "United States of America",
		native_name: "United States of America",
		locales: "en",
	},
	{
		country: "UY",
		en_name: "Uruguay",
		native_name: "Uruguay",
		locales: "es",
	},
	{
		country: "UZ",
		en_name: "Uzbekistan",
		native_name: "",
		locales: "uz, kaa",
	},
	{
		country: "VA",
		en_name: "City of the Vatican",
		native_name: "Città del Vaticano",
		locales: "it",
	},
	{
		country: "VC",
		en_name: "Saint Vincent and the Grenadines",
		native_name: "Saint Vincent and the Grenadines",
		locales: "en",
	},
	{
		country: "VE",
		en_name: "Venezuela",
		native_name: "Venezuela",
		locales: "es",
	},
	{
		country: "VG",
		en_name: "British Virgin Islands",
		native_name: "British Virgin Islands",
		locales: "en",
	},
	{
		country: "VI",
		en_name: "United States Virgin Islands",
		native_name: "United States Virgin Islands",
		locales: "en",
	},
	{
		country: "VN",
		en_name: "Vietnam",
		native_name: "Việt Nam",
		locales: "vi",
	},
	{
		country: "VU",
		en_name: "Vanuatu",
		native_name: "Vanuatu",
		locales: "bi, en, fr",
	},
	{
		country: "WF",
		en_name: "Wallis and Futuna",
		native_name: "Wallis-et-Futuna",
		locales: "fr",
	},
	{
		country: "WS",
		en_name: "Samoa",
		native_name: "Samoa",
		locales: "sm, en",
	},
	{
		country: "YE",
		en_name: "Yemen",
		native_name: "اليَمَن",
		locales: "ar",
	},
	{
		country: "YT",
		en_name: "Mayotte",
		native_name: "Mayotte",
		locales: "fr",
	},
	{
		country: "ZA",
		en_name: "South Africa",
		native_name: "South Africa",
		locales: "en, af, st, tn, xh, zu",
	},
	{
		country: "ZM",
		en_name: "Zambia",
		native_name: "Zambia",
		locales: "en",
	},
	{
		country: "ZW",
		en_name: "Zimbabwe",
		native_name: "Zimbabwe",
		locales: "en, sn, nd",
	},
];
