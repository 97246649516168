import React, { useContext, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router-dom";
import { FieldArray, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { COUNTRIES_CLIENT } from "../../../api/api_helper";
import { AccordionBody, AccordionHeader, AccordionItem, Button, Card, CardBody, CardTitle, Col, Container, Form, Row, UncontrolledAccordion } from "reactstrap";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import LoadingSpinner from "../../../components/UI/LoadingSpinner";
import TextInput from "../../../components/Inputs/TextInput";
import { countryLocales } from "../../../helpers/countryLocales";
import FloatingSelectInput from "../../../components/Inputs/FloatingSelectInput";
import { currencyOptions } from "../../../helpers/currencies";
import { v4 as uuid } from "uuid";
import { ResourceContext } from "../../../context";
import toastr from "toastr";
import { currencyNames, regionNames } from "../../../helpers/intl";
import { PrestationType } from "../../../api/hotel-api.service.ts";

const pmsOptions = [{ label: "MEWS", value: "mews" }];

const prestationTypeList = [
	"Room",
	"Menu",
	"SoftDrink",
	"AlcoholicDrink",
	"HallOccupation",
	"GourmetBreak",
	// "MenuPackage",
	// "Drink",
	// "ResidentialSeminar",
	// "StudyDay",
	"Salon",
	"PrivateSpace",
	"Entertainment",
	"Activity",
	// "CustomSeminar",
	"Parking",
	"Various",
	"SpecialPrestation",
	"SpecialPrestation2",
	"SpecialPrestation3",
	"SpecialPrestation4",
	"SpecialPrestation5",
	"SpecialPrestation6",
];

async function getCountry(id) {
	return await COUNTRIES_CLIENT.getById(id);
}

function CountryForm() {
	const { t } = useTranslation();
	const { id } = useParams();
	const [countryData, setCountryData] = useState(null);
	const [loading, setLoading] = useState(false);
	const resources = useContext(ResourceContext);

	const formik = useFormik({
		enableReinitialize: true,
		validateOnBlur: true,
		initialValues: {
			name: countryData?.name ?? "",
			code: countryData?.code ?? "",
			locale:
				countryData?.locale ??
				countryLocales
					.find((c) => countryData?.code === c.country)
					?.locales.split(",")[0]
					.trim() ??
				"",
			currencyName: countryData?.currencyName ?? "",
			currencyCode: countryData?.currencyCode ?? "",
			currencySymbol: countryData?.currencySymbol ?? "",
			taxBrackets: countryData?.taxBrackets ?? [],
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required(t("RequiredFieldInputError")),
			code: Yup.string().required(t("RequiredFieldInputError")),
			currencyName: Yup.string().required(t("RequiredFieldInputError")),
			locale: Yup.string().required(t("RequiredFieldInputError")),
			taxBrackets: Yup.array(
				Yup.object().shape({
					taxDenomination: Yup.string().required(t("RequiredFieldInputError")),
					taxes: Yup.array(
						Yup.object().shape({
							title: Yup.string().required(t("RequiredFieldInputError")),
							countryPrestationTaxes: Yup.array(
								Yup.object().shape({
									value: Yup.number().min(0, t("PercentageInputError")).max(100, t("PercentageInputError")),
									pmsTaxes: Yup.array(
										Yup.object().shape({
											psmType: Yup.string(),
											code: Yup.string(),
										})
									),
								})
							),
						})
					),
					stayTaxes: Yup.array(
						Yup.object().shape({
							title: Yup.string().required(t("RequiredFieldInputError")),
						})
					),
				})
			),
		}),
		onSubmit: async (values) => {
			setLoading(true);
			try {
				await COUNTRIES_CLIENT.update(id, values);
			} catch (error) {
				toastr.error(error);
			} finally {
				setLoading(false);
			}
		},
	});

	const handleCountryChange = (country) => {
		const { country: code, en_name: name, locales } = country;
		formik.setValues((prev) => ({
			...prev,
			name,
			code,
			locale: locales.split(",")[0].trim(),
		}));
	};

	const handleCurrencyChange = (currency) => {
		const { symbol_native, name, code } = currency;
		formik.setValues((prev) => ({
			...prev,
			currencyName: name,
			currencyCode: code,
			currencySymbol: symbol_native,
		}));
	};

	const handleAddTaxBracket = () => {
		formik.setFieldValue("taxBrackets", [
			...formik.values.taxBrackets,
			{
				uuid: uuid(),
				countryId: id,
				taxDenomination: "",
			},
		]);
	};

	const handleAddTax = (taxBraketId, taxBracketIndex) => {
		formik.setFieldValue(`taxBrackets.${taxBracketIndex}.taxes`, [
			...(formik.values.taxBrackets?.[taxBracketIndex]?.taxes ?? []),
			{
				uuid: uuid(),
				taxBracketId: taxBraketId ?? undefined,
				title: "",
				countryPrestationTaxes: prestationTypeList.map((type) => ({
					uuid: uuid(),
					type: type,
					value: 0,
					pmsTaxes: pmsOptions.map((o) => ({ psmType: o.value, code: "" })),
				})),
			},
		]);
	};

	const handleAddStayTax = (taxBraketId, taxBracketIndex) => {
		formik.setFieldValue(`taxBrackets.${taxBracketIndex}.stayTaxes`, [
			...(formik.values.taxBrackets?.[taxBracketIndex]?.stayTaxes ?? []),
			{
				uuid: uuid(),
				taxBracketId: taxBraketId ?? undefined,
				title: "",
			},
		]);
	};

	async function fetchCountry() {
		if (id) {
			setLoading(true);
			await getCountry(id)
				.then((countryResponse) => {
					const countryTaxesWithPmsTypes = countryResponse.taxBrackets.map((tb) => ({
						...tb,
						taxes: tb.taxes.map((tx) => ({
							...tx,
							countryPrestationTaxes: tx.countryPrestationTaxes
								.map((ct) => ({
									...ct,
									pmsTaxes: ct.pmsTaxes.length > 0 ? ct.pmsTaxes : pmsOptions.map((o) => ({ psmType: o.value, code: "" })),
								}))
								.filter((e) => e.type !== PrestationType.Unspecified),
						})),
					}));
					// console.log(countryTaxesWithPmsTypes);
					setCountryData(JSON.parse(JSON.stringify({ ...countryResponse, taxBrackets: countryTaxesWithPmsTypes })));
				})
				.finally(() => setLoading(false));
		}
	}

	useEffect(() => {
		fetchCountry();
	}, []);

	return (
		<div className="page-content">
			<MetaTags>
				<title>{t("Country")} - Quotelo</title>
			</MetaTags>
			<Breadcrumbs title={t("Countries")} breadcrumbItem={t("EditCountry")} link="/countries" />
			<Container fluid>
				<FormikProvider value={formik}>
					<Form className="needs-validation" onSubmit={formik.handleSubmit}>
						{loading ? (
							<Card>
								<CardBody>
									<LoadingSpinner />
								</CardBody>
							</Card>
						) : (
							<>
								{/* COUNTRY INFO */}
								<Card>
									<CardBody>
										<CardTitle>{t("Country")}</CardTitle>
										<Row>
											<Col>
												<FloatingSelectInput
													label={t("Country")}
													options={countryLocales}
													getOptionLabel={(option) => regionNames.of(option.country)}
													getOptionValue={(option) => option.country}
													value={countryLocales.find((c) => c.country === formik.values.code)}
													onChange={(newCountry) => handleCountryChange(newCountry)}
													name={"name"}
													validation={formik}
													placeholder={t("SelectCountry")}
													isDisabled
												/>
											</Col>
											<Col>
												<TextInput label={t("CountryCode")} value={formik.values.code} disabled readOnly />
											</Col>
											<Col>
												<FloatingSelectInput
													label={t("Currency")}
													options={currencyOptions}
													getOptionLabel={(option) => currencyNames.of(option.code) + ` (${option.symbol_native})`}
													getOptionValue={(option) => option.code}
													value={currencyOptions.find((e) => e.code === formik.values.currencyCode)}
													onChange={(currency) => handleCurrencyChange(currency)}
													name="currencyName"
													validation={formik}
													placeholder={t("SelectCurrency")}
												/>
											</Col>
											<Col>
												<TextInput label={t("Locale")} value={formik.values.locale} disabled readOnly />
											</Col>
										</Row>
									</CardBody>
								</Card>

								{/* TAX BRACKETS */}
								<Card>
									<CardBody>
										<CardTitle>{t("TaxBrackets")}</CardTitle>
										<p>{t("TaxBracketsDescription")}</p>

										<FieldArray
											name="taxBrackets"
											render={(arrayHelpers) => (
												<div>
													<Button color="info" onClick={handleAddTaxBracket}>
														{t("AddTaxBracket")}
													</Button>
													<div className="mt-3">
														<UncontrolledAccordion defaultOpen={"0"}>
															{formik.values.taxBrackets.map((taxBracket, taxBracketIndex) => (
																<AccordionItem key={taxBracket.id ?? taxBracket.uuid}>
																	<AccordionHeader targetId={`${taxBracketIndex + 1}`}>
																		<div className="fw-bold">{taxBracket.taxDenomination || "???"}</div>
																	</AccordionHeader>
																	<AccordionBody accordionId={`${taxBracketIndex + 1}`}>
																		<div>
																			<Row className="d-flex align-items-center">
																				<Col>
																					<TextInput
																						label={t("TaxBracketName")}
																						name={`taxBrackets.${taxBracketIndex}.taxDenomination`}
																						htmlId={`${taxBracket?.id ?? taxBracket?.uuid}`}
																						value={taxBracket.taxDenomination}
																						onChange={formik.handleChange}
																						isRequired
																						validation={formik}
																						invalid={formik.errors?.taxBrackets?.[taxBracketIndex]?.taxDenomination}
																					/>
																				</Col>
																			</Row>

																			{/* TAXES */}
																			<div className="d-flex justify-content-between align-items-center">
																				<h5>{t("Taxes")}</h5>

																				<Button color="info" onClick={() => handleAddTax(taxBracket.id, taxBracketIndex)} className="mb-3">
																					{t("AddTax")}
																				</Button>
																			</div>
																			<UncontrolledAccordion defaultOpen="0">
																				{taxBracket?.taxes?.length > 0
																					? taxBracket.taxes.map((tax, taxIndex) => (
																							<AccordionItem key={tax.id ?? tax.uuid}>
																								<AccordionHeader targetId={`${taxIndex + 1}`}>
																									<div className="fw-bold">{tax.title || "???"}</div>
																								</AccordionHeader>
																								<AccordionBody accordionId={`${taxIndex + 1}`}>
																									<TextInput
																										label={t("TaxName")}
																										name={`taxBrackets.${taxBracketIndex}.taxes.${taxIndex}.title`}
																										htmlId={`${tax?.id ?? tax?.uuid}`}
																										value={tax.title ?? ""}
																										onChange={formik.handleChange}
																										isRequired
																										validation={formik}
																										invalid={formik.errors?.taxBrackets?.[taxBracketIndex]?.taxes?.[taxIndex]?.title}
																									/>

																									<div className="d-flex flex-wrap">
																										{tax.countryPrestationTaxes
																											.sort((a, b) => prestationTypeList.indexOf(a.type) - prestationTypeList.indexOf(b.type))
																											// .filter((e) => e.type !== PrestationType.Unspecified)
																											.map((prestationTax, prestationTaxIndex) => {
																												return (
																													<Row key={prestationTaxIndex} className="w-100">
																														<Col xs={4}>
																															<Row>
																																<Col xs={6}>
																																	<div className="d-flex align-items-center fw-bold h-100 mb-3">
																																		{resources.PrestationType[prestationTax.type]}
																																	</div>
																																</Col>
																																<Col xs={6}>
																																	<TextInput
																																		label={t("TaxInPercentage")}
																																		htmlId={`${tax.type + prestationTax.id}`}
																																		type="number"
																																		name={`taxBrackets.${taxBracketIndex}.taxes.${taxIndex}.countryPrestationTaxes.${prestationTaxIndex}.value`}
																																		value={prestationTax.value}
																																		onChange={formik.handleChange}
																																		isRequired
																																		validation={formik}
																																		invalid={
																																			formik.errors?.taxBrackets?.[taxBracketIndex]?.taxes?.[taxIndex]
																																				?.countryPrestationTaxes?.[prestationTaxIndex]?.value
																																		}
																																	/>
																																</Col>
																															</Row>
																														</Col>

																														<Col xs={8}>
																															{pmsOptions.map((option) => (
																																<Row key={option.value}>
																																	<Col xs={4}>
																																		<span className="d-flex align-items-center justify-content-end fw-bold h-100  mb-3">
																																			{option.label}
																																		</span>
																																	</Col>
																																	<Col xs={8}>
																																		<TextInput
																																			label={t("Code")}
																																			htmlId={`${tax.type + prestationTax.id}`}
																																			name={`taxBrackets.${taxBracketIndex}.taxes.${taxIndex}.countryPrestationTaxes.${prestationTaxIndex}.pmsTaxes.${prestationTax.pmsTaxes.findIndex(
																																				(e) => e.psmType === option.value
																																			)}.code`}
																																			value={prestationTax.pmsTaxes.find((e) => e.psmType === option.value)?.code}
																																			onChange={formik.handleChange}
																																		/>
																																	</Col>
																																</Row>
																															))}
																														</Col>
																													</Row>
																												);
																											})}
																									</div>
																								</AccordionBody>
																							</AccordionItem>
																					  ))
																					: null}
																			</UncontrolledAccordion>

																			{/* STAY TAXES */}
																			<div className="d-flex justify-content-between align-items-center mt-4">
																				<h5>{t("StayTaxes")}</h5>

																				<Button color="info" onClick={() => handleAddStayTax(taxBracket.id, taxBracketIndex)} className="mb-3">
																					{t("AddStayTax")}
																				</Button>
																			</div>
																			{taxBracket?.stayTaxes?.length > 0 ? (
																				taxBracket.stayTaxes.map((stayTax, stayTaxIndex) => (
																					<Row key={stayTax?.id ?? stayTax?.uuid}>
																						<Col xs={6}>
																							<TextInput
																								htmlId={`${stayTax?.id ?? stayTax?.uuid}`}
																								label={t("StayTaxName")}
																								name={`taxBrackets.${taxBracketIndex}.stayTaxes.${stayTaxIndex}.title`}
																								value={stayTax.title ?? ""}
																								onChange={formik.handleChange}
																								isRequired
																								validation={formik}
																								invalid={formik.errors?.taxBrackets?.[taxBracketIndex]?.stayTaxes?.[stayTaxIndex]?.title}
																							/>
																						</Col>
																					</Row>
																				))
																			) : (
																				<p>{t("NoStayTaxCreated")}</p>
																			)}
																		</div>
																	</AccordionBody>
																</AccordionItem>
															))}
														</UncontrolledAccordion>
													</div>
												</div>
											)}
										/>
									</CardBody>
								</Card>

								<Button color="info" type="submit">
									{t("Save")}
								</Button>

								{/* DEBUG */}
								{process.env.REACT_APP_ENV === "development" ? (
									<Card>
										<Row>
											<Col>
												<h5>Current form values</h5>
												<pre>{JSON.stringify(formik.values, null, 2)}</pre>
											</Col>
											<Col>
												<h5>Errors</h5>
												<pre>{JSON.stringify(formik.errors, null, 2)}</pre>
											</Col>
										</Row>
									</Card>
								) : null}
							</>
						)}
					</Form>
				</FormikProvider>
			</Container>
		</div>
	);
}

export default CountryForm;
