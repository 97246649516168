import React from "react";

const Pagination = ({ currentPage, pageCount, onChange }) => {
	return (
		<nav>
			{pageCount > 1 && (
				<ul className="pagination justify-content-end mt-4">
					<li className="page-item" onClick={() => currentPage > 1 && onChange(currentPage - 1)}>
						<a className="page-link" to="#" aria-label="Previous">
							<i className="bx bx-chevron-left"></i>
						</a>
					</li>
					{[...Array(pageCount).keys()].map((page) => (
						<li key={page} className={`page-item ${page + 1 === currentPage ? "active" : ""}`} onClick={(e) => onChange(page + 1)}>
							<a className="page-link" to="#">
								{page + 1}
							</a>
						</li>
					))}
					<li className="page-item" onClick={() => currentPage < pageCount && onChange(currentPage + 1)}>
						<a className="page-link" to="#" aria-label="Next">
							<i className="bx bx-chevron-right"></i>
						</a>
					</li>
				</ul>
			)}
		</nav>
	);
};

export default Pagination;
